import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  AccountBalanceWalletOutlined,
  Close,
  Delete,
  Done,
  EventAvailable,
  EventNote,
  ExpandMore,
  MoreHoriz,
  NorthEast,
  PendingActions,
  SouthWest,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEmpSalary } from "../../reducers/employeeSalarySlice";
import { useSelector } from "react-redux";
import useCommandRRedirect from "../../hooks/useCommandRRedirect";
import {
  CloseCircle,
  PercentageCircle,
  TickCircle,
  Timer1,
} from "iconsax-react";
import { data } from "jquery";

function List(props) {
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const [attendaceList, setAttendaceList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const [chnageStatus, setChnageStatus] = useState("");
  const [Data, setData] = useState({});
  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = [
    {
      name: "Employee Id",
      selector: (row) => row.emp_code,
    },
    {
      name: "Employee Name",
      selector: (row) => row.employee_name,
    },
    {
      name: "Request Date",
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
    },
    {
      name: "Check type",
      selector: (row) =>
        row?.update_time.length !== 0 ? row?.update_time[0]?.check_type : "",
    },

    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: (
        <div>
          Action By <br />
        </div>
      ),
      selector: (row) => row.auth_name,
    },
    {
      name: "Logs",
      center: true,
      selector: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={
              <RB.Popover id="popover-positioned-bottom">
                <RB.Popover.Body className="p-0 overflow-auto popover-scroll">
                  <div className="list-group">
                    {row.update_time
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .sort((a, b) => {
                        const aTime = moment(a.date);
                        const bTime = moment(b.date);
                        const aIsAm = aTime.hours() < 12;
                        const bIsAm = bTime.hours() < 12;
                        if (aIsAm !== bIsAm) {
                          return aIsAm ? -1 : 1;
                        }
                        return aTime - bTime;
                      })
                      .map((log, index) => {
                        if (index % 2 === 0) {
                          const checkOutIndex = index + 1;
                          const checkOutTime = row.update_time[checkOutIndex];
                          const checkInTime = moment(log.date).format(
                            "hh:mm A"
                          );
                          const newCheckInTime = log.new_time
                            ? moment(log.new_time).format("hh:mm A")
                            : null;
                          const checkOutDisplayTime = checkOutTime
                            ? moment(checkOutTime.date).format("hh:mm A")
                            : "Missing";
                          const newCheckOutTime =
                            checkOutTime && checkOutTime.new_time
                              ? moment(checkOutTime.new_time).format("hh:mm A")
                              : null;

                          const isCheckInSame = checkInTime === newCheckInTime;
                          const isCheckOutSame =
                            checkOutDisplayTime === newCheckOutTime;

                          return (
                            <div
                              key={index}
                              className="list-group-item d-flex align-items-center"
                            >
                              <div className="d-flex align-items-center">
                                <SouthWest />
                                <div className="d-flex flex-column">
                                  {isCheckInSame ? (
                                    <span className="text-danger">
                                      {checkInTime}
                                    </span>
                                  ) : (
                                    <>
                                      <span>{checkInTime}</span>
                                      {newCheckInTime && (
                                        <span className="text-danger">
                                          {newCheckInTime}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="mx-2">-</div>
                              <div className="d-flex align-items-center">
                                <NorthEast />
                                <div className="d-flex flex-column">
                                  {isCheckOutSame ? (
                                    <span className="text-danger">
                                      {checkOutDisplayTime}
                                    </span>
                                  ) : (
                                    <>
                                      <span>{checkOutDisplayTime}</span>
                                      {newCheckOutTime && (
                                        <span className="text-danger">
                                          {newCheckOutTime}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <Link className="btn btn-flat">
              <Timer1 />
            </Link>
          </RB.OverlayTrigger>
        </>
      ),
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {row.status == "Pending" && (
            <>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={handleApprove(row)}
              >
                <TickCircle />
              </RB.Button>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={handleReject(row)}
              >
                <CloseCircle />
              </RB.Button>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getAttendaceList();
  }, [page]);

  const getAttendaceList = () => {
    utils
      .callAPI(
        "get",
        `Attendance/team-list-Assign?page=${page}&per_page=${countPerPage}&type=your`
        // `Attendance/update-list?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setAttendaceList(res);
        } else {
          setAttendaceList([]);
        }
      })
      .catch((err) => {
        setAttendaceList([]);
      });
  };

  const handleApprove = useCallback(
    (row) => async () => {
      setData({ ...row, status: "Approved" });
      setChnageStatus("Approved");
      setIsSubmit(true);
    },
    []
  );

  const handleReject = useCallback(
    (row) => async () => {
      setData({ ...row, status: "Rejected" });
      setChnageStatus("Rejected");
      setIsSubmit(true);
    },
    []
  );

  useEffect(() => {
    if (isSubmit) {
      const updatedData = {
        ...Data,
        status: chnageStatus,
        id: Data.attendance_update_id,
        attendance_history_id: Data.id,
      };

      utils
        .callAPI("post", "/Attendance/approval", updatedData)
        .then((res) => {
          if (res) {
            getAttendaceList();
            setIsSubmit(false);
          }
        })
        .catch(() => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);
  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action"></div>
        </RB.Card.Header>
        <RB.Card.Body>
          <DataTable
            columns={columns}
            data={attendaceList.result}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={attendaceList.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            striped
            onChangePage={(page) => setPage(page)}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            //subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
