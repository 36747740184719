import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Cancel, Delete, Done } from "@mui/icons-material";
import * as utils from "../../../Utils/comman";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Check, CloseCircle, Eye, TickCircle } from "iconsax-react";
import { useLocation } from "react-router-dom";
import ReasonModal from "../../ReasonModal";

function TeamExpenseList({ setLocal, setData, localData, getLocalList }) {
  // const [localData, setExpenses] = useState(localData);
  const [page, setPage] = useState(1);
  // console.log(localData)
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [rejectReasonError, setRejectReasonError] = useState("");

  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const location = useLocation();

  const [rejectReason, setRejectReason] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );
  // const handleAddClick = (e) => {
  //   const propdata = {
  //     mode: "add",
  //   };
  //   props.onChange(propdata);
  // };

  const columns = [
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    },
    // {
    //   name: "User Name",
    //   selector: (row) => row.emp_name,
    // },
    {
      name: "Expense Id",
      selector: (row) => row?.expId,
    },
    {
      name: "Senior Status",
      selector: (row) => row.seniorStatus,
    },
    {
      name: <div>Hr Status</div>,
      selector: (row) => {
        if (row.hr_submit === null || row.hr_submit === undefined) {
          return "";
        } else if (row.hr_submit === "0") {
          return "Pending";
        } else if (row.hr_submit === "1") {
          return "Approved";
        } else {
          return "";
        }
      },
    },
    {
      name: <div>Hr Remark</div>,
      selector: (row) => row.hr_remark,
      cell: (row) => {
        const wordCount = row?.hr_remark?.split(" ").length;
        return wordCount > 3 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("HR Remark", row.hr_remark)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.hr_remark}</span>
        );
      },
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {location.pathname === "/expenses/all-expense" ? (
            // Show the button only if exp_approval is "Approve"
            row.seniorStatus === "Approve" && (
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={handleView(row)}
              >
                <Eye />
              </RB.Button>
            )
          ) : (
            // For all other paths, show the button as it is
            <>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={handleView(row)}
              >
                <Eye />
              </RB.Button>
            </>
          )}

          {location.pathname === "/expenses/accountant-expense" && (
            <>
              {row.accountStatus == "Pending" && (
                <RB.Button
                  className="btn btn-flat ms-1"
                  onClick={handleApprove(row)}
                >
                  <TickCircle />
                </RB.Button>
              )}
              {row.accountStatus == "Pending" && (
                <RB.Button
                  className="btn btn-flat ms-1"
                  onClick={handleReject(row)}
                >
                  <CloseCircle />
                </RB.Button>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   getTravelExpenses();
  // }, [page, selectedStatus]);

  // const getTravelExpenses = () => {
  //   utils
  //     .callAPI("get", `/expense/team-list?filters=${selectedStatus}`)
  //     .then((res) => {
  //       if (typeof res !== "undefined") {
  //         setExpenses(res);
  //       } else {
  //         setExpenses([]);
  //       }
  //     })
  //     .catch((err) => {
  //       setExpenses([]);
  //     });
  // };

  const handleView = useCallback((row) => async () => {
    setLocal(true);
    setData(row);
  });

  const handleApprove = useCallback((row) => async () => {
    const formData = new FormData();
    formData.append("type", "local");
    formData.append("id", row.id);
    formData.append("status", "Approve");
    formData.append("reason", "");

    utils
      .callAPI("post", "/expense/accountant-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          const isAccountantExpensePage =
            location.pathname === "/expenses/accountant-expense";

          getLocalList(isAccountantExpensePage);
        } else {
        }
      })
      .catch((err) => {});
  });

  const handleReject = useCallback((row) => async () => {
    setSelectedExpense(row);
    setShowRejectModal(true);
  });

  const handleRejectSubmit = async () => {
    if (!rejectReason.trim()) {
      setRejectReasonError("Reject reason cannot be blank");
      return;
    }
    const formData = new FormData();
    formData.append("type", "local");
    formData.append("id", selectedExpense.id);
    formData.append("status", "Reject");
    formData.append("reason", rejectReason);
    utils
      .callAPI("post", "/expense/accountant-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          const isAccountantExpensePage =
            location.pathname === "/expenses/accountant-expense";

          getLocalList(isAccountantExpensePage);
          setShowRejectModal(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>Local Expenses</h4>
          <div className="card-header-action"></div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Row>
            <RB.Col md={4}>
              {/* <RB.Form.Select
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                {statusOptions.map((status, index) => (
                  <option key={index} value={status}>
                    {status || "Select Status"}
                  </option>
                ))}
              </RB.Form.Select> */}
            </RB.Col>
          </RB.Row>
          <DataTable
            columns={columns}
            data={localData.result}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={localData.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            striped
            onChangePage={(page) => setPage(page)}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
      <RB.Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Enter Reject Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Form.Control
            as="textarea"
            placeholder="Enter reject reason..."
            value={rejectReason}
            onChange={(e) => {
              setRejectReason(e.target.value);
              setRejectReasonError("");
            }}
          />
          {rejectReasonError && (
            <div className="text-danger mt-2">{rejectReasonError}</div>
          )}
        </RB.Modal.Body>

        <RB.Modal.Footer>
          <RB.Button
            variant="secondary"
            onClick={() => setShowRejectModal(false)}
          >
            Close
          </RB.Button>
          <RB.Button variant="danger" onClick={handleRejectSubmit}>
            Reject
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </div>
  );
}

export default TeamExpenseList;
