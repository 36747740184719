import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Email, MoreHoriz, RuleFolder } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { More } from "iconsax-react";
import AcceptedList from "./AcceptedList";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";

function List(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({
    interested: "",
    remark: "",
    file: null,
    joiningDate: "",
    offerStatus: "",
  });

  const handleClose = () => {
    setShow(false);
    setFormData({
      interested: "",
      remark: "",
      file: null,
    });
  };
  const handleShow = () => setShow(true);
  const [error, setError] = useState("");

  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
    },
    {
      name: "Expected Date of Joining",
      selector: (row) =>
        moment(row.expected_date_of_joining).format("YYYY-MM-DD"),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0">
                  <div className="list-group">
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Proposed Manpower",
                      "proposed_manpower.interested"
                    ) && (
                      <>
                        {!row.interested && (
                          <div
                            className="list-group-item list-group-item-action monthYear-card"
                            onClick={handleModel("intrested", row)}
                          >
                            <EditIcon /> Interested
                          </div>
                        )}
                      </>
                    )}
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Proposed Manpower",
                      "proposed_manpower.send_email"
                    ) && (
                      <div
                        className="list-group-item list-group-item-action monthYear-card"
                        onClick={handleModel("sendEmail", row)}
                      >
                        <Email /> Send Email
                      </div>
                    )}
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Proposed Manpower",
                      "proposed_manpower.offer_letter"
                    ) && (
                      <div
                        className="list-group-item list-group-item-action monthYear-card"
                        onClick={handleModel("offerLetter", row)}
                      >
                        <RuleFolder /> Offer Letter Status
                      </div>
                    )}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <Link className="btn btn-flat">
              <More />
            </Link>
          </RB.OverlayTrigger>
        </>
      ),
    },
  ];

  useEffect(() => {
    getManPower();
  }, [page, filterText]);

  const getManPower = () => {
    utils
      .callAPI(
        "get",
        `/department/getManpowerList?per_page=${countPerPage}&page=${page}&id=&status`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setList(res);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        setList([]);
      });
  };

  const handleModel = useCallback(
    (action, row) => async () => {
      setSelectedRow(row);
      if (action === "intrested") {
        setModalContent("interested");
      } else if (action === "sendEmail") {
        setModalContent("sendEmail");
      } else if (action === "offerLetter") {
        setModalContent("offerLetter");
      }
      handleShow();
    },
    []
  );

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        file: file,
      }));
      setError("");
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        file: null,
      }));
      setError("Please upload a PDF file.");
    }
  };

  const handleSubmit = async () => {
    if (modalContent === "interested") {
      handleinterested();
    } else if (modalContent === "sendEmail") {
      handleSendEmail();
    } else if (modalContent == "offerLetter") {
      handleOfferLatter();
    }
  };

  const renderModalContent = () => {
    if (modalContent === "interested") {
      return (
        <>
          <RB.FloatingLabel
            controlId="floatingSelect"
            label="Interested"
            className="floating-label-fancy"
          >
            <RB.Form.Control
              as="select"
              name="interested"
              value={formData.interested}
              onChange={handleFormChange}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </RB.Form.Control>
          </RB.FloatingLabel>
          {formData.interested === "No" && (
            <RB.FloatingLabel
              controlId="floatingRemark"
              label="Remark"
              className="floating-label-fancy mt-2"
            >
              <RB.Form.Control
                type="text"
                placeholder="Enter Remark"
                name="remark"
                value={formData.remark}
                onChange={handleFormChange}
              />
            </RB.FloatingLabel>
          )}
        </>
      );
    } else if (modalContent === "sendEmail") {
      return (
        <RB.Form.Group controlId="formFile">
          <RB.Form.Label>Upload Letter</RB.Form.Label>
          <RB.Form.Control
            type="file"
            name="file"
            onChange={handleFileChange}
          />
          {error && <div className="text-danger">{error}</div>}
        </RB.Form.Group>
      );
    } else if (modalContent === "offerLetter") {
      return (
        <>
          <RB.FloatingLabel
            controlId="floatingSelect"
            label="Offer Status"
            className="floating-label-fancy"
          >
            <RB.Form.Control
              as="select"
              name="offerStatus"
              value={formData.offerStatus}
              onChange={handleFormChange}
            >
              <option value="">Select</option>
              <option value="Accept">Accept</option>
              <option value="Reject">Reject</option>
            </RB.Form.Control>
          </RB.FloatingLabel>
          {formData.offerStatus === "Accept" && (
            <RB.FloatingLabel
              controlId="floatingJoiningDate"
              // label="Joining Date"
              className="floating-label-fancy mt-2"
            >
              <DatePicker
                selected={formData.joiningDate}
                onChange={(date) =>
                  setFormData({ ...formData, joiningDate: date })
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
                placeholderText="Expected Date of Joining"
                // minDate={new Date()}
                required
              />
            </RB.FloatingLabel>
          )}
        </>
      );
    }
    return null;
  };

  const handleinterested = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("id", selectedRow.id);
    bodyFormData.append("department_id", selectedRow.department_id);
    bodyFormData.append("ref_by", selectedRow.ref_by);
    bodyFormData.append("name", selectedRow.name);
    bodyFormData.append("email", selectedRow.email);
    bodyFormData.append("current_ctc", selectedRow.current_ctc);
    bodyFormData.append("expected_ctc", selectedRow.expected_ctc);
    bodyFormData.append("zone", selectedRow.zone);
    bodyFormData.append("monthly_sales_value", selectedRow.monthly_sales_value);
    bodyFormData.append("yearly_target", selectedRow.yearly_target);
    bodyFormData.append(
      "expected_date_of_joining",
      selectedRow.expected_date_of_joining
    );
    bodyFormData.append("interested", formData.interested);
    bodyFormData.append("remarks", formData.remark);

    utils
      .callAPI("post", `/department/save_manpower`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          handleClose();
          getManPower();
        } else {
          handleClose();
        }
      })
      .catch((err) => {});
  };

  const handleSendEmail = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("id", selectedRow.id);
    bodyFormData.append("document", formData.file);

    utils
      .callAPI("post", `/department/sendofferletter`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          handleClose();
          getManPower();
        } else {
          handleClose();
        }
      })
      .catch((err) => {});
  };

  const handleOfferLatter = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("id", selectedRow.id);
    bodyFormData.append("status", formData.offerStatus);
    bodyFormData.append(
      "joining_date",
      moment(formData.joiningDate).format("YYYY-MM-DD")
    );

    utils
      .callAPI("post", `/department/offerletterstatus`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          handleClose();
          getManPower();
        } else {
          handleClose();
        }
      })
      .catch((err) => {});
  };

  const [activeTab, setActiveTab] = useState("list");

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow">
          <RB.Card.Header>
            <h4></h4>
            <div className="card-header-action">
              {utils.hasPermission(
                primaryMenuRes,
                "Proposed Manpower",
                "proposed_manpower.create"
              ) && (
                <RB.Button
                  className="btn btn-primary me-2"
                  onClick={handleAddClick}
                >
                  Add Proposed Manpower
                </RB.Button>
              )}
            </div>
          </RB.Card.Header>
          <RB.Card.Body>
            {utils.hasPermission(
              primaryMenuRes,
              "Proposed Manpower",
              "proposed_manpower.view"
            ) && (
              <RB.Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="leave-request-tabs"
              >
                <RB.Tab eventKey="list" title="All">
                  {activeTab === "list" && (
                    <DataTable
                      columns={columns}
                      data={list.result}
                      highlightOnHover
                      responsive
                      pagination
                      paginationServer
                      paginationTotalRows={list.total}
                      paginationPerPage={countPerPage}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      striped
                      onChangePage={(page) => setPage(page)}
                      persistTableHead
                      customStyles={{
                        head: {
                          style: {
                            fontSize: "0.875rem",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      // subHeader
                      // subHeaderComponent={subHeaderComponent}
                    />
                  )}
                </RB.Tab>
                <RB.Tab eventKey="AcceptedList" title="Offer latter accepted">
                  {activeTab === "AcceptedList" && (
                    <AcceptedList props={props} />
                  )}
                </RB.Tab>
              </RB.Tabs>
            )}
          </RB.Card.Body>
        </RB.Card>
      </div>
      <RB.Modal show={show} onHide={handleClose}>
        {modalContent === "sendEmail" && (
          <RB.Modal.Header closeButton>
            <RB.Modal.Title>Upload Letter</RB.Modal.Title>
          </RB.Modal.Header>
        )}
        {modalContent === "interested" && (
          <RB.Modal.Header closeButton>
            <RB.Modal.Title>Interested</RB.Modal.Title>
          </RB.Modal.Header>
        )}
        {modalContent === "offerLetter" && (
          <RB.Modal.Header closeButton>
            <RB.Modal.Title>Offer Letter Status</RB.Modal.Title>
          </RB.Modal.Header>
        )}
        {/* <RB.Modal.Header closeButton>
          <RB.Modal.Title>Action</RB.Modal.Title>
        </RB.Modal.Header> */}
        <RB.Modal.Body>
          <RB.Form>{renderModalContent()}</RB.Form>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleClose}>
            Close
          </RB.Button>
          <RB.Button
            variant="primary"
            onClick={handleSubmit}
            disabled={
              (modalContent === "interested" && !formData.interested) ||
              (modalContent === "interested" &&
                formData.interested === "No" &&
                !formData.remark) ||
              (modalContent === "sendEmail" && !formData.file) ||
              (modalContent === "offerLetter" &&
                (!formData.offerStatus ||
                  (formData.offerStatus === "Accept" && !formData.joiningDate)))
            }
          >
            Submit
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
    </>
  );
}

export default List;
