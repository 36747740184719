import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import { Edit2, ProfileAdd, Trash } from "iconsax-react";
import * as utils from "../../Utils/comman";
import AssignPermission from "./AssignPermission";
import { useSelector } from "react-redux";

function Role() {
  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [role, setRole] = useState([]);
  const [editId, setEditId] = useState();
  const [assignPermission, setAssignPermission] = useState(false);
  const [currentRole, setCurrentRole] = useState({});
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  useEffect(() => {
    getRoleList();
  }, [page]);

  const getRoleList = () => {
    utils
      .callAPI("get", `/role/list?page=${page}&per_page=${countPerPage}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setRole(res);
        } else {
          setRole([]);
        }
      })
      .catch((err) => {
        setRole([]);
      });
  };

  const statusList = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
  ];

  const columns = [
    {
      name: "Designation",
      selector: (row) => row.roleName,
    },
    {
      name: "Status",
      selector: (row) => (row.isActive == "1" ? "Active" : "inActive"),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Designation",
            "designation.edit"
          ) && (
            <RB.Button className="btn btn-flat" onClick={handelEdit(row)}>
              <Edit2 />
            </RB.Button>
          )}
          {utils.hasPermission(
            primaryMenuRes,
            "Designation",
            "designation.delete"
          ) && (
            <RB.Button className="btn btn-flat" onClick={showDelete(row)}>
              <Trash />
            </RB.Button>
          )}
          {utils.hasPermission(
            primaryMenuRes,
            "Designation",
            "designation.permission"
          ) && (
            <RB.Button
              className="btn btn-flat"
              onClick={handleAssignPermission(row)}
            >
              <ProfileAdd />
            </RB.Button>
          )}
        </>
      ),
    },
  ];

  const handleAssignPermission = useCallback((row) => async () => {
    setCurrentRole(row);
    setAssignPermission(true);
  });

  const handelEdit = useCallback((row) => async () => {
    setEditId(row.id);
    setRoleName(row.roleName);
    setStatus(row.isActive);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          utils
            .callAPI("get", `/role/delete?id=${row.id}`, "")
            .then((res) => {
              if (typeof res !== "undefined") {
                getRoleList();
              }
            })
            .catch((err) => {});
        }
      }
    });
  });

  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }

      bodyFormData.append("roleName", roleName);
      bodyFormData.append("isActive", status);

      utils
        .callAPI("post", `/role/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setRoleName("");
            setStatus("");
            getRoleList();
          }
        })
        .catch((err) => {});
    }
  }, [isSubmit]);

  const clear = () => {
    setRoleName("");
    setStatus("");
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Designation</h1>
          </div>
          <div className="section-body">
            {assignPermission ? (
              <AssignPermission
                setAssignPermission={setAssignPermission}
                setCurrentRole={setCurrentRole}
                currentRole={currentRole}
              />
            ) : (
              <RB.Card className="">
                <RB.Card.Body>
                  <RB.Row>
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Designation",
                      "designation.create"
                    ) && (
                      <RB.Col md={4}>
                        <RB.Card>
                          <RB.Card.Body>
                            <RB.Form
                              noValidate
                              validated={validated}
                              onSubmit={handleSubmit}
                            >
                              <div className="mb-3">
                                <RB.FloatingLabel
                                  controlId="roleName"
                                  label="Designation Name"
                                  className="floating-label-fancy"
                                >
                                  <RB.Form.Control
                                    type="text"
                                    placeholder="Enter Designation Name"
                                    value={roleName}
                                    onChange={handleRoleNameChange}
                                    required
                                  />
                                  <RB.Form.Control.Feedback type="invalid">
                                    Please enter a designation name.
                                  </RB.Form.Control.Feedback>
                                </RB.FloatingLabel>
                              </div>

                              <div className="mb-3">
                                <RB.FloatingLabel
                                  controlId="status"
                                  label="Status"
                                  className="floating-label-fancy"
                                >
                                  <RB.Form.Control
                                    as="select"
                                    value={status}
                                    onChange={handleStatusChange}
                                    required
                                  >
                                    {statusList.map((role) => (
                                      <option key={role.id} value={role.id}>
                                        {role.name}
                                      </option>
                                    ))}
                                  </RB.Form.Control>
                                  <RB.Form.Control.Feedback type="invalid">
                                    Please select a status.
                                  </RB.Form.Control.Feedback>
                                </RB.FloatingLabel>
                              </div>

                              <div className="text-center mt-3">
                                <RB.Button type="submit" variant="primary">
                                  Submit
                                </RB.Button>
                                <RB.Button
                                  className="ms-1"
                                  onClick={clear}
                                  variant="dark"
                                >
                                  Clear
                                </RB.Button>
                              </div>
                            </RB.Form>
                          </RB.Card.Body>
                        </RB.Card>
                      </RB.Col>
                    )}
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Designation",
                      "designation.view"
                    ) && (
                      <RB.Col md={8}>
                        <RB.Card>
                          <RB.Card.Body>
                            <DataTable
                              columns={columns}
                              data={role?.result}
                              highlightOnHover
                              responsive
                              pagination
                              striped
                              paginationServer
                              paginationTotalRows={role.total}
                              paginationPerPage={countPerPage}
                              paginationComponentOptions={{
                                noRowsPerPage: true,
                              }}
                              onChangePage={(page) => setPage(page)}
                              persistTableHead
                              customStyles={{
                                head: {
                                  style: {
                                    fontSize: "0.875rem", // Adjust the font size as needed
                                    fontWeight: "bold", // Adjust the font weight as needed
                                  },
                                },
                              }}
                              //   subHeader
                              // subHeaderComponent={subHeaderComponent}
                            />
                          </RB.Card.Body>
                        </RB.Card>
                      </RB.Col>
                    )}
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Role;
