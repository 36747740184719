import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as utils from "../../../Utils/comman";
import { AddCircle, Eye } from "iconsax-react";
import { setAction } from "../../../reducers/actionSlice";
import { setDates } from "../../../reducers/dateSlice";
import { clearExp } from "../../../reducers/updateExpenseSlice";
import ReasonModal from "../../ReasonModal";

function ApprovedPlanList({ setCompo, setTravelPlan, setShowScedule }) {
  const [travelList, setTravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  useEffect(() => {
    getTravelList();
  }, [page, location.pathname]);

  const getTravelList = () => {
    const url =
      location.pathname == "/expenses/your-expense"
        ? `/travel/listForUser?page=${page}&per_page=${countPerPage}`
        : `/expense/team-list?filters=${selectedStatus}`;
    utils
      .callAPI("get", url)
      .then((res) => {
        if (typeof res !== "undefined") {
          setTravelList(res);
        } else {
          setTravelList([]);
        }
      })
      .catch((err) => {
        setTravelList([]);
      });
  };

  const columns = [
    {
      name: (
        <div>
          Request Id <br />
        </div>
      ),
      selector: (row) => row.requestId,
    },
    {
      name: (
        <div>
          Start Date <br />
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          End Date <br />
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },
    // {
    //   name: <div>Type</div>,
    //   selector: (row) =>
    //     row.type === "area_visit" ? "Area Wise" : "Distributor Wise",
    // },
    {
      name: <div>Senior Status</div>,
      selector: (row) => row.exp_approval,
    },
    {
      name: <div>Hr Status</div>,
      selector: (row) => {
        if (row.hr_submit === null || row.hr_submit === undefined) {
          return "";
        } else if (row.hr_submit === "0") {
          return "Pending";
        } else if (row.hr_submit === "1") {
          return "Approved";
        } else {
          return "";
        }
      },
    },
    {
      name: <div>Hr Remark</div>,
      selector: (row) => row.hr_remark,
      cell: (row) => {
        const wordCount = row?.hr_remark?.split(" ").length;
        return wordCount > 3 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("HR Remark", row.hr_remark)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.hr_remark}</span>
        );
      },
    },
    
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      cell: (row) => (
        <>
          {location.pathname == "/expenses/your-expense" && (
            <>
              {row.exp_approval == "Pending" && (
                <RB.Button
                  className="btn btn-flat ms-1"
                  onClick={AddExpense(row)}
                >
                  <AddCircle />
                </RB.Button>
              )}
            </>
          )}
          <RB.Button
            className="btn btn-flat ms-1"
            onClick={handleViewExpense(row)}
          >
            <Eye />
          </RB.Button>
        </>
      ),
    },
  ];

  const AddExpense = useCallback((row) => async () => {
    dispatch(setAction("add"));
    setShowScedule(true);
    setTravelPlan(row);
    dispatch(clearExp());
  });

  const handleViewExpense = useCallback((row) => async () => {
    dispatch(setAction("view"));
    setShowScedule(true);
    setTravelPlan(row);
  });

  const filteredData = useMemo(() => {
    return (
      travelList.result?.filter((item) => {
        const filterTextLower = filterText.toLowerCase();
        return (
          item.approvalStatus === "Approved" &&
          (item.requestId.toLowerCase().includes(filterTextLower) ||
            item.employeeId.toLowerCase().includes(filterTextLower) ||
            item.destination.toLowerCase().includes(filterTextLower))
        );
      }) || []
    );
  }, [filterText, travelList]);

  useEffect(() => {
    if (filteredData.length > 0) {
      const dates = filteredData.map((item) => ({
        departureDate: item.departureDate,
        returnDate: item.returnDate,
      }));
      dispatch(setDates(dates));
    }
  }, [filteredData, dispatch]);

  const statusOptions = ["", "Pending", "Approve", "Reject"];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <>
      {/* <div className="section-body">
        <RB.Card className="card shadow">
          <RB.Card.Body> */}
      {location.pathname !== "/expenses/your-expense" && (
        <RB.Row>
          <RB.Col md={4}>
            <RB.Form.Select
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              {statusOptions.map((status, index) => (
                <option key={index} value={status}>
                  {status || "Select Status"}
                </option>
              ))}
            </RB.Form.Select>
          </RB.Col>
        </RB.Row>
      )}
      {/* {filteredData.length > 0 && ( */}
      <DataTable
        columns={columns}
        data={filteredData}
        highlightOnHover
        responsive
        striped
        pagination
        paginationServer
        paginationTotalRows={travelList.total}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => setPage(page)}
        persistTableHead
        customStyles={{
          head: {
            style: {
              fontSize: "0.875rem", // Adjust the font size as needed
              fontWeight: "bold", // Adjust the font weight as needed
            },
          },
        }}
      />
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
      {/* )} */}
      {/* </RB.Card.Body>
        </RB.Card>
      </div> */}
    </>
  );
}

export default ApprovedPlanList;
