import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReasonModal from "../ReasonModal";
import * as utils from "../../Utils/comman";
import { AddCircle, Eye } from "iconsax-react";

function ApprovedTravelList({ setCompo, setTravelPlan, setExpenseCompo }) {
  const [travelList, setTravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    getTravelList();
  }, [page, location.pathname]);

  const getTravelList = () => {
    utils
      .callAPI(
        "get",
        `/travel/listForUser?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setTravelList(res);
        } else {
          setTravelList([]);
        }
      })
      .catch((err) => {
        setTravelList([]);
      });
  };

  const columns = [
    {
      name: (
        <div>
          Request Id <br />
        </div>
      ),
      selector: (row) => row.requestId,
    },
    {
      name: (
        <div>
          Start Date <br />
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          End Date <br />
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },
    {
      name: <div>Type</div>,
      selector: (row) =>
        row.type === "area_visit" ? "Area Wise" : "Distributor Wise",
    },
    {
      name: <div>Status</div>,
      selector: (row) => row.approvalStatus,
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      cell: (row) => (
        <>
          <RB.Button className="btn btn-flat ms-1" onClick={AddExpense(row)}>
            <AddCircle />
          </RB.Button>
          <RB.Button
            className="btn btn-flat ms-1"
            onClick={handleViewExpense(row)}
          >
            <Eye />
          </RB.Button>
        </>
      ),
    },
  ];

  const AddExpense = useCallback((row) => async () => {
    setCompo("add");
    setExpenseCompo("add");
    setTravelPlan(row);
  });
  const handleViewExpense = useCallback((row) => async () => {
    setExpenseCompo("view");
    setTravelPlan(row);
  });

  const filteredData = useMemo(() => {
    return (
      travelList.result?.filter((item) => {
        const filterTextLower = filterText.toLowerCase();
        return (
          item.approvalStatus === "Approved" &&
          (item.requestId.toLowerCase().includes(filterTextLower) ||
            item.employeeId.toLowerCase().includes(filterTextLower) ||
            item.destination.toLowerCase().includes(filterTextLower))
        );
      }) || []
    );
  }, [filterText, travelList]);

  //   const handleAddClick = (e) => {
  //     const propdata = {
  //       mode: "add",
  //     };
  //     props.onChange(propdata);
  //   };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      {/* <div className="section-body">
        <RB.Card className="card shadow">
          <RB.Card.Body> */}
            {filteredData.length > 0 && (
              <DataTable
                columns={columns}
                data={filteredData}
                highlightOnHover
                responsive
                striped
                pagination
                paginationServer
                paginationTotalRows={travelList.total}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => setPage(page)}
                persistTableHead
                customStyles={{
                  head: {
                    style: {
                      fontSize: "0.875rem", // Adjust the font size as needed
                      fontWeight: "bold", // Adjust the font weight as needed
                    },
                  },
                }}
              />
            )}
          {/* </RB.Card.Body>
        </RB.Card>
      </div> */}
    </>
  );
}

export default ApprovedTravelList;
