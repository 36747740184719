import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";
import { Edit2, Eye, Trash } from "iconsax-react";
import { Link } from "react-router-dom";
import ReasonModal from "../ReasonModal";

function List(props) {
  const [leaveList, setleaveList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [hideAction, setHideAction] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = [
    {
      name: (
        <div>
          Requested Date <br />
        </div>
      ),
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Start Date <br />
        </div>
      ),
      selector: (row) => moment(row.start_date).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          End Date <br />
        </div>
      ),
      selector: (row) => moment(row.end_date).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Leave Day <br />
        </div>
      ),
      selector: (row) => (row.leave_day == "full" ? "Full Day" : "Custome"),
    },
    {
      name: (
        <div>
          Leave Type <br />
        </div>
      ),
      selector: (row) => row.leave_name,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      cell: (row) => {
        const wordCount = row?.reason?.split(" ").length;
        console.log(wordCount);
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Reason", row.reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.reason}</span>
        );
      },
    },
    {
      name: "Cancel Reason",
      selector: (row) => row.auth_reason,
      cell: (row) => {
        const wordCount = row.auth_reason?.split(" ").length;
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Cancel Reason", row.auth_reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.auth_reason}</span>
        );
      },
    },
    {
      name: (
        <div>
          Status <br />
        </div>
      ),
      selector: (row) => {
        switch (row.status) {
          case "0":
            return "Pending";
          case "1":
            return "Reject";
          case "2":
            return "Approved";
          case "3":
            return "Cancel";
          default:
            return "";
        }
      },
    },
    {
      name: (
        <div>
          Action By <br />
        </div>
      ),
      selector: (row) => row.auth_name,
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      center: true,
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Your Leave Request",
            "leave_request.edit"
          ) && (
            <>
              {" "}
              {(row.status == "0" || row.status == "1") && (
                <RB.Button className="btn btn-flat" onClick={handelEdit(row)}>
                  <Edit2 />
                </RB.Button>
              )}
            </>
          )}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "Your Leave Request",
            "leave_request.delete"
          ) && (
            <>
              {" "}
              {row.status == "0" && (
                <RB.Button
                  className="btn btn-flat ms-1"
                  onClick={showDelete(row)}
                >
                  <Trash />
                </RB.Button>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getleaveList();
  }, [page]);

  const getleaveList = () => {
    utils
      .callAPI("get", `/leave/leave-request/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setleaveList(res);
          setHideAction(res?.result?.some((item) => item.status !== "0"));
        } else {
          setleaveList([]);
        }
      })
      .catch((err) => {
        setleaveList([]);
      });
  };

  const handelEdit = useCallback(
    (row) => () => {
      const propdata = {
        mode: "edit",
        rowData: row,
      };
      props.onChange(propdata);
    },
    []
  );

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/leave/leave-request/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getleaveList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow ">
          <RB.Card.Header>
            <h4></h4>
            <div className="card-header-action">
              {utils.hasPermission(
                primaryMenuRes,
                "Your Leave Request",
                "leave_request.create"
              ) && (
                <RB.Button
                  className="btn btn-primary me-2"
                  onClick={handleAddClick}
                >
                  Apply Leave
                </RB.Button>
              )}
            </div>
          </RB.Card.Header>
          <RB.Card.Body>
            {utils.hasPermission(
              primaryMenuRes,
              "Your Leave Request",
              "leave_request.view"
            ) && (
              <DataTable
                columns={columns}
                data={leaveList.result}
                highlightOnHover
                responsive
                pagination
                paginationServer
                paginationTotalRows={leaveList.total}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => setPage(page)}
                persistTableHead
                customStyles={{
                  head: {
                    style: {
                      fontSize: "0.875rem", // Adjust the font size as needed
                      fontWeight: "bold", // Adjust the font weight as needed
                    },
                  },
                }}
                striped
                // subHeader
                // subHeaderComponent={subHeaderComponent}
              />
            )}
          </RB.Card.Body>
        </RB.Card>
      </div>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default List;
