import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Visibility } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import { Eye } from "iconsax-react";

function List(props) {
  const [CouponHistoy, setCouponHistoy] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [empList, setEmpList] = useState([]);
  const getFormattedMonthYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    const currentDate = new Date();
    return getFormattedMonthYear(currentDate);
  });

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const [active, setActive] = useState(false);
  const csvLink = createRef();

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Client Id",
      selector: (row) => row.clientId,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Coupon Count",
      selector: (row) => row.coupon_count,
    },
    {
      name: "Total Amount",
      selector: (row) => row?.total_amount,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {row?.coupon_count > 0 && (
            <RB.Button
              className="btn-flat"
              onClick={handleView(row)}
            >
              <Eye />
            </RB.Button>
          )}
        </>
      ),
    },
  ];

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  useEffect(() => {
    getCouponHistoy();
    // getEmployeeList();
  }, [page]);

  const getCouponHistoy = () => {
    utils
      .callAPI(
        "get",
        `Coupon/scannedCoupon?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setCouponHistoy(res);
          setFilterReset(false);
        } else {
          setCouponHistoy([]);
          setFilterReset(false);
        }
      })
      .catch((err) => {
        setCouponHistoy([]);
        setFilterReset(false);
      });
  };

  //   const getEmployeeList = () => {
  //     utils
  //       .callAPI("get", `/employee/list`)
  //       .then((res) => {
  //         if (typeof res !== "undefined") {
  //           setEmpList(res.result);
  //         } else {
  //           setEmpList([]);
  //         }
  //       })
  //       .catch((err) => {
  //         setEmpList([]);
  //       });
  //   };

  //   function handleDownload() {
  //     setCsvData([]);

  //     utils
  //       .callAPI(
  //         "post",
  //         `/Coupon/payslip/export-all-employee?month_year=${selectedMonthYear}&employeeId=${selectedEmployee}`
  //       )
  //       .then((res) => {
  //         if (res && Object.keys(res).length > 0) {
  //           console.log("Response is this", res);
  //           setCsvData(res);
  //           setActive(true);
  //         } else {
  //           setCsvData([]);
  //         }
  //       })
  //       .catch((err) => {
  //         setCsvData([]);
  //       });
  //   }

  //   const subHeaderComponent = useMemo(() => {
  //     const handleClear = () => {
  //       if (filterText) {
  //         setResetPaginationToggle(!resetPaginationToggle);
  //         setFilterText("");
  //       }
  //     };

  //     return (
  //       <FilterComponent
  //         onFilter={(e) => setFilterText(e.target.value)}
  //         onClear={handleClear}
  //         filterText={filterText}
  //       />
  //     );
  //   }, [filterText, resetPaginationToggle]);

  //   const months = useMemo(() => {
  //     const options = [];
  //     const currentDate = new Date();
  //     for (let i = 0; i < 12; i++) {
  //       const date = new Date(currentDate);
  //       date.setMonth(date.getMonth() - i);
  //       const monthYear = getFormattedMonthYear(date);
  //       options.push(
  //         <option key={i} value={monthYear}>
  //           {monthYear}
  //         </option>
  //       );
  //     }
  //     return options;
  //   }, []);

  //   const handleFilter = () => {
  //     getCouponHistoy();
  //   };

  //   const handleReset = () => {
  //     const currentDate = new Date();
  //     setSelectedMonthYear(getFormattedMonthYear(currentDate));
  //     setSelectedEmployee("");
  //     setFilterReset(true);
  //   };

  //   useEffect(() => {
  //     if (filterReset) {
  //       getCouponHistoy();
  //     }
  //   }, [filterReset]);
  return (
    <div className="section-body">
      <RB.Card className="card">
        {/* <RB.Card.Header className="border-0">
          <h4>List</h4>
          <div className="card-header-action">
            {utils.hasPermission(primaryMenuRes, "User", "user.create") && (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Add User
              </RB.Button>
            )}
          </div>
        </RB.Card.Header> */}
        <RB.Card.Body>
          {/* <RB.Row className="justify-content-between">
            <RB.Col md="auto">
              <div className="d-flex justify-content-start" text-center>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                >
                  <option>Select an employee...</option>
                  {empList?.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {`${employee.firstName} ${employee.lastName}`}
                    </option>
                  ))}
                </RB.Form.Select>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedMonthYear}
                  onChange={(e) => setSelectedMonthYear(e.target.value)}
                >
                  <option value="">Select a month-year...</option>
                  {months}
                </RB.Form.Select>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={handleFilter}
                >
                  Find
                </RB.Button>
                <RB.Button
                  className="ms-2"
                  variant="secondary"
                  onClick={handleReset}
                >
                  Reset
                </RB.Button>
              </div>
            </RB.Col>
            <RB.Col md="auto">
              {" "}
              <div className=" justify-content-end" text-center>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  Download
                </RB.Button>

                <CSVLink data={csvData} ref={csvLink} target="_blank" />
              </div>
            </RB.Col>
          </RB.Row> */}
          <DataTable
            columns={columns}
            data={CouponHistoy.result}
            highlightOnHover
            responsive
            pagination
            striped
            paginationServer
            paginationTotalRows={CouponHistoy.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
