import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Check, CheckCircle, Close, Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Layout from "../../Layout";
import {
  Car,
  CloseCircle,
  DocumentText,
  Eye,
  TickCircle,
  Ticket,
} from "iconsax-react";
import ReasonModal from "../ReasonModal";

function ManagerHrApproval() {
  const [travelList, settravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [hideAction, setHideAction] = useState(false);
  const location = useLocation();
  const [status, setstatus] = useState("");
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, seterror] = useState("");
  const [id, setId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  console.log(userProfile);
  const [showDoc, setShowDoc] = useState(false);
  const [travelDocuments, settravelDocuments] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showticket, setShowTicket] = useState(false);
  const [selectedTravelRequest, setSelectedTravelRequest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );
  const handleCloseTicket = () => setShowTicket(false);
  const handleShowTicket = () => setShowTicket(true);
  const [showTravelPlanDetail, setshowTravelPlanDetail] = useState(false);
  const [travelPlans, settravelPlans] = useState([]);
  // const handleAddClick = (e) => {
  //   const propdata = {
  //     mode: "add",
  //   };
  //   props.onChange(propdata);
  // };

  const columns = [
    {
      name: (
        <div>
          Request Id <br />
        </div>
      ),
      selector: (row) => row.requestId,
    },
    {
      name: (
        <div>
          Emp Code <br />
        </div>
      ),
      selector: (row) => row.emp_code,
    },
    {
      name: (
        <div>
          Employee name <br />
        </div>
      ),
      selector: (row) => row.full_name,
    },
    // {
    //   name: (
    //     <div>
    //       Destination <br />
    //     </div>
    //   ),
    //   selector: (row) => row.destination,
    // },
    {
      name: (
        <div>
          Departure Date <br />
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Return Date <br />
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },
    // {
    //   name: <div>Type</div>,
    //   selector: (row) =>
    //     row.type == "area_visit" ? "Area Wise" : "Distributor Wise",
    // },
    // {
    //   name: <div>State</div>,
    //   selector: (row) => row.state_name,
    // },
    // {
    //   name: <div>City</div>,
    //   selector: (row) => row.city_name,
    // },
    {
      name: <div>Approve By</div>,
      selector: (row) => row.approval_name,
    },
    {
      name: <div>Reject Reason</div>,
      selector: (row) => row.reason,
      cell: (row) => {
        const wordCount = row?.reason?.split(" ").length;
        console.log(wordCount);
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Reason", row.reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.reason}</span>
        );
      },
    },
    // {
    //   name: (
    //     <div>
    //       Approval By Hr <br />
    //     </div>
    //   ),
    //   selector: (row) => row.hr_name,
    // },
    // {
    //   name: (
    //     <div>
    //       Manager Approval <br />
    //     </div>
    //   ),
    //   selector: (row) => row.approval_status_manager,
    // },
    {
      name: (
        <div>
          Status <br />
        </div>
      ),
      selector: (row) => row.approvalStatus,
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      cell: (row) => (
        <>
          {/* {utils.hasPermission(
              primaryMenuRes,
              "Your Travel Request",
              "travel_request.edit"
            ) && (
              <>
                {" "}
                {row.approval_status_manager == "Pending" && (
                  <RB.Button
                    className="btn-circle"
                    variant="outline-primary"
                    size="sm"
                    onClick={handelEdit(row)}
                  >
                    <EditIcon />
                  </RB.Button>
                )}
              </>
            )}
            &nbsp;
            {utils.hasPermission(
              primaryMenuRes,
              "Your Travel Request",
              "travel_request.delete"
            ) && (
              <>
                {" "}
                {row.approval_status_manager == "Pending" && (
                  <RB.Button
                    variant="outline-danger"
                    className="btn-circle"
                    size="sm"
                    onClick={showDelete(row)}
                  >
                    <Delete />
                  </RB.Button>
                )}
              </>
            )} */}
          {utils.hasPermission(
            primaryMenuRes,
            "Other Travel Request",
            "other_travel.request"
          ) && (
            <>
              {row.approvalStatus == "Pending" && (
                <RB.Button
                  className="btn-flat me-2"
                  onClick={hanldeApprove(row, "Approved")}
                >
                  <TickCircle />
                </RB.Button>
              )}
            </>
          )}
          {utils.hasPermission(
            primaryMenuRes,
            "Other Travel Request",
            "other_travel.request"
          ) && (
            <>
              {" "}
              {row.approvalStatus == "Pending" && (
                <RB.Button
                  className="btn-flat me-2"
                  onClick={hanldeApprove(row, "Rejected")}
                >
                  <CloseCircle />
                </RB.Button>
              )}
            </>
          )}
          {/* {row?.travelDocuments && row?.travelDocuments?.length !== 0 && (
            <RB.Button
              variant="outline-info"
              className="btn-circle"
              size="sm"
              onClick={handleView(row)}
            >
              <Eye />
            </RB.Button>
          )} */}
          {row.travelTicket.length !== 0 && (
            <>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={showTicket(row)}
              >
                <Ticket />
              </RB.Button>
            </>
          )}
          {row.travelPlan.length !== 0 && (
            <>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={showTravelPlan(row)}
              >
                <Car />
              </RB.Button>
            </>
          )}
        </>
      ),
    },
  ];

  const showTicket = useCallback(
    (row) => () => {
      setSelectedTravelRequest(row);
      setShowTicket(true);
    },
    []
  );

  const showTravelPlan = useCallback(
    (row) => () => {
      handleShow();
      settravelPlans(row?.travelPlan);
      setshowTravelPlanDetail(true);
    },
    []
  );

  const handleView = useCallback((row) => async () => {
    console.log(row);
    settravelDocuments(row.travelDocuments);
    setShowDoc(true);
  });

  const handleBackClick = () => {
    settravelDocuments([]);
    setShowDoc(false);
  };
  const hanldeApprove = useCallback((row, st) => async () => {
    setId(row.id);
    setstatus(st);
    setshowTravelPlanDetail(false);
    if (st == "Approved") {
      setIsSubmit(true);
    } else {
      handleShow();
    }
  });

  const handleReject = (currentSt) => {
    if (!reason) {
      seterror("Please enter reason.");
      return;
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const bodyFormData = new FormData();
      bodyFormData.append("travelReqId", id);
      bodyFormData.append("status", status);
      bodyFormData.append("reason", reason);
      utils
        .callAPI("post", `/travel/status`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setReason("");
            getTravelList();
            handleClose();
          }
          setIsSubmit(false);
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    getTravelList();
  }, [page, location.pathname]);

  const getTravelList = () => {
    const apiUrl =
      location.pathname === "/travel/junior-travel-req"
        ? `/travel/list?page=${page}&per_page=${countPerPage}`
        : `/travel/listForUser?page=${page}&per_page=${countPerPage}`;

    utils
      .callAPI("get", apiUrl)
      .then((res) => {
        if (typeof res !== "undefined") {
          settravelList(res);
          setHideAction(res?.result?.some((item) => item.status !== "0"));
        } else {
          settravelList([]);
        }
      })
      .catch((err) => {
        settravelList([]);
      });
  };

  // const handelEdit = useCallback((row) => async () => {
  //   const propdata = {
  //     mode: "edit",
  //     rowData: row,
  //   };
  //   props.onChange(propdata);
  // });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/leave/leave-request/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getTravelList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const statusOptions = ["", "Pending", "Approved", "Cancelled"];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const filteredData = useMemo(() => {
    return (
      travelList.result?.filter((item) => {
        const filterTextLower = filterText.toLowerCase();
        return (
          (!selectedStatus || item.approvalStatus === selectedStatus) &&
          (item.requestId.toLowerCase().includes(filterTextLower) ||
            item.employeeId.toLowerCase().includes(filterTextLower) ||
            item.destination.toLowerCase().includes(filterTextLower))
        );
      }) || []
    );
  }, [filterText, selectedStatus, travelList]);

  const handleDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(
        new Blob([blob], { type: "image/jpeg" })
      );
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 10000);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };

  const handleDownloadTicket = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(
        new Blob([blob], { type: "image/jpeg" })
      );

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Ticket";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 10000);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };
  return (
    <>
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Travel Request</h1>
            </div>

            <div className="section-body">
              {showDoc ? (
                <>
                  <RB.Card className="card shadow ">
                    <RB.Card.Header>
                      <h4></h4>
                      <div className="card-header-action">
                        <RB.Button
                          className="btn btn-primary me-2"
                          onClick={handleBackClick}
                        >
                          Back
                        </RB.Button>
                      </div>
                    </RB.Card.Header>
                    <RB.Card.Body>
                      <RB.Row className="mt-3 g-3">
                        {travelDocuments?.map((doc, index) => (
                          <RB.Col key={index} className="col-md-6 col-lg-3">
                            {console.log(doc)}
                            <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                              <div className="travel-doc-upload">
                                <a
                                  href={doc.travelDocument}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-decoration-none text-dark"
                                >
                                  {doc.travelDocument.endsWith(".pdf") ? (
                                    <i className="material-icons-outlined md-64">
                                      picture_as_pdf
                                    </i>
                                  ) : (
                                    <i className="material-icons-outlined md-64">
                                      image
                                    </i>
                                  )}
                                  <div className="text-center">
                                    Travel Bill {index + 1}
                                  </div>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between  position-absolute top-100 start-50 translate-middle">
                                {/* <RB.Button
                                variant="danger"
                                className="btn btn-sm btn-circle me-2"
                                onClick={() =>
                                  handleDeleteDocument(doc.travelDocId)
                                }
                              >
                                <i className="material-icons-outlined">
                                  delete
                                </i>
                              </RB.Button> */}
                                {!doc.travelDocument.endsWith(".pdf") && (
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                      handleDownload(
                                        doc.travelDocument,
                                        `TravelBill${index + 1}.jpg`
                                      )
                                    }
                                  >
                                    <i className="material-icons-outlined">
                                      file_download
                                    </i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </RB.Col>
                        ))}
                      </RB.Row>
                    </RB.Card.Body>
                  </RB.Card>
                </>
              ) : (
                <>
                  <RB.Card className="card shadow ">
                    <RB.Card.Header>
                      <h4></h4>
                      <div className="card-header-action"></div>
                    </RB.Card.Header>
                    <RB.Card.Body>
                      {utils.hasPermission(
                        primaryMenuRes,
                        "Other Travel Request",
                        "other_travel.request"
                      ) && (
                        <DataTable
                          columns={columns}
                          data={travelList.result}
                          highlightOnHover
                          responsive
                          striped
                          pagination
                          paginationServer
                          paginationTotalRows={travelList.total}
                          paginationPerPage={countPerPage}
                          paginationComponentOptions={{
                            noRowsPerPage: true,
                          }}
                          onChangePage={(page) => setPage(page)}
                          persistTableHead
                          customStyles={{
                            head: {
                              style: {
                                fontSize: "0.875rem", // Adjust the font size as needed
                                fontWeight: "bold", // Adjust the font weight as needed
                              },
                            },
                          }}
                          // subHeader
                          // subHeaderComponent={subHeaderComponent}
                        />
                      )}
                    </RB.Card.Body>
                  </RB.Card>
                </>
              )}
            </div>
            <RB.Modal
              show={show}
              onHide={handleClose}
              size={showTravelPlanDetail ? "xl" : ""}
            >
              <RB.Modal.Header closeButton>
                <RB.Modal.Title>
                  {showTravelPlanDetail
                    ? "Travel Plan Details"
                    : "Reject Travel Request"}
                </RB.Modal.Title>
              </RB.Modal.Header>
              <RB.Modal.Body>
                {showTravelPlanDetail ? (
                  <RB.Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Destination</th>
                        <th>Name of Destination</th>
                        <th>State</th>
                        <th>District</th>
                        <th>City</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {travelPlans.map((plan, index) => (
                        <tr key={plan.id}>
                          <td>{index + 1}</td>
                          <td>
                            {moment(plan.start_date).format("DD-MM-YYYY")}
                          </td>
                          <td>{moment(plan.end_date).format("DD-MM-YYYY")}</td>
                          <td>
                            {plan.destination == "area_visit"
                              ? "Area Wise"
                              : "Distributor Wise"}
                          </td>
                          <td>{plan.name_of_destination}</td>
                          <td>{plan.state_name}</td>
                          <td>{plan.district_name}</td>
                          <td>{plan.city_name}</td>
                          <td>{plan.remark}</td>
                        </tr>
                      ))}
                    </tbody>
                  </RB.Table>
                ) : (
                  <>
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Enter reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>
                    {error && <div className="text-danger">{error}</div>}
                  </>
                )}
              </RB.Modal.Body>
              <RB.Modal.Footer>
                {showTravelPlanDetail ? (
                  <RB.Button variant="secondary" onClick={handleClose}>
                    Close
                  </RB.Button>
                ) : (
                  <RB.Button
                    variant="primary"
                    onClick={() => handleReject("Reject")}
                  >
                    Reject
                  </RB.Button>
                )}
              </RB.Modal.Footer>
            </RB.Modal>
          </section>
        </div>
      </Layout>

      <RB.Modal show={showticket} onHide={handleCloseTicket} size="lg">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Travel Tickets</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Row className="justify-content-center">
            {selectedTravelRequest?.travelTicket?.map((ticket) => {
              const fileExtension = ticket.ticket_image
                .split(".")
                .pop()
                .toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                fileExtension
              );
              const isPDF = fileExtension === "pdf";
              const isDoc = ["doc", "docx", "document"].includes(fileExtension);

              return (
                <RB.Col
                  key={ticket.id}
                  md={4}
                  className="d-flex justify-content-center ticket-col"
                >
                  <div className="position-relative ticket-wrapper">
                    <p>{ticket.headName}</p>

                    {isImage && (
                      <>
                        <a
                          href={ticket.ticketPath}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={ticket.ticketPath}
                            alt="ticket"
                            className="ticket-image"
                          />
                        </a>

                        <RB.Button
                          variant="primary"
                          className="btn btn-sm btn-circle download-btn"
                          onClick={() =>
                            handleDownloadTicket(ticket.ticketPath)
                          }
                        >
                          <i className="material-icons-outlined">
                            file_download
                          </i>
                        </RB.Button>
                      </>
                    )}

                    {isPDF && (
                      <a
                        href={ticket.ticketPath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="material-icons-outlined md-128">
                          picture_as_pdf
                        </i>
                      </a>
                    )}

                    {isDoc && (
                      <a
                        href={ticket.ticketPath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DocumentText size={70} />
                      </a>
                    )}
                  </div>
                </RB.Col>
              );
            })}
          </RB.Row>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleCloseTicket}>
            Close
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default ManagerHrApproval;
