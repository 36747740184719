import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import {
  Cancel,
  Delete,
  Done,
  MoreHoriz,
  PendingActions,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CloseCircle, Eye, More, TickCircle, Timer1 } from "iconsax-react";
import ReasonModal from "../ReasonModal";

function OtherTeamLeave() {
  const [leaveList, setleaveList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [hideAction, setHideAction] = useState(false);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [formData, setFormData] = useState({
    reason: "",
  });
  const [isSubmit, SetIsSbmit] = useState(false);
  const [error, seterror] = useState("");

  const handleClose = () => {
    setShow(false);
    setFormData({
      reason: "",
    });
  };
  const handleShow = () => setShow(true);
  const [currentStatus, setCurrentStatus] = useState("");
  const [crrentdata, setCrrentdata] = useState({});
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  const columns = [
    {
      name: "Id",
      selector: (row) => row.emp_code,
    },
    {
      name: "Name",
      selector: (row) => row.employee_name,
    },
    {
      name: (
        <div>
          Requested Date <br />
        </div>
      ),
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("DD-MM-YYYY"),
    },
    {
      name: "End  Date",
      selector: (row) => moment(row.end_date).format("DD-MM-YYYY"),
    },
    {
      name: "Leave Day",
      selector: (row) => (row.leave_day == "full" ? "Full Day" : "Custome"),
    },
    {
      name: (
        <div>
          Leave Type <br />
        </div>
      ),
      selector: (row) => row.leave_name,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      cell: (row) => {
        const wordCount = row?.reason?.split(" ").length;
        console.log(wordCount);
        return wordCount > 1 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Reason", row.reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.reason}</span>
        );
      },
    },
    {
      name: "Cancel Reason",
      selector: (row) => row.auth_reason,
      cell: (row) => {
        const wordCount = row.auth_reason?.split(" ").length;
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Cancel Reason", row.auth_reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.auth_reason}</span>
        );
      },
    },
    {
      name: "Status ",
      selector: (row) => getStatusName(row.status),
    },
    {
      name: (
        <div>
          Action By <br />
        </div>
      ),
      selector: (row) => row.auth_name,
    },
    {
      name: "Team Log",
      center: true,
      selector: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0 overflow-auto popover-scroll ">
                  <div className="list-group ">
                    {row.logData.length !== 0 ? (
                      <>
                        {row.logData.map((log, index) => (
                          <div className="list-group-item ">
                            {log.reporting_manager_name}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="list-group-item">...</div>
                      </>
                    )}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <Link className="btn btn-flat">
              <Timer1 />
            </Link>
          </RB.OverlayTrigger>
        </>
      ),
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {row.status !== "1" && row.status !== "3" && row.status !== "2" && (
            <RB.OverlayTrigger
              trigger="click"
              rootClose
              key="bottom"
              placement="bottom"
              overlay={
                <RB.Popover id={`popover-positioned-bottom`}>
                  <RB.Popover.Body className="p-0">
                    <div className="list-group">
                      {row.status === "0" && (
                        <>
                          <Link
                            className="list-group-item list-group-item-action"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLeave(row, "2")();
                            }}
                          >
                            <TickCircle /> Approve
                          </Link>
                          {/* <li className="mb-2">
                            <RB.Button
                              title="Approve"
                              variant="outline-success"
                              size="sm"
                            ></RB.Button>
                          </li> */}
                          <Link
                            className="list-group-item list-group-item-action"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLeave(row, "1")();
                            }}
                          >
                            <CloseCircle /> Reject
                          </Link>
                        </>
                      )}

                      {row.status === "0" && (
                        <Link
                          className="list-group-item list-group-item-action"
                          onClick={(e) => {
                            e.preventDefault();
                            handleLeave(row, "3")();
                          }}
                        >
                          <CloseCircle /> Cancel
                        </Link>
                      )}
                    </div>
                  </RB.Popover.Body>
                </RB.Popover>
              }
            >
              <Link className="btn btn-flat">
                <More />
              </Link>
            </RB.OverlayTrigger>
          )}
        </>
      ),
    },
  ];

  const getStatusName = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Reject";
      case "2":
        return "Approved";
      case "3":
        return "Cancel";
      default:
        return "Unknown";
    }
  };

  const handleLeave = useCallback((row, status) => async () => {
    if (status === "1" || status === "3") {
      setCurrentStatus(status);
      setCrrentdata(row);
      handleShow();
      return;
    } else {
      SetIsSbmit(true);
      setCurrentStatus(status);
      setCrrentdata(row);
      // leave();
    }
  });

  useEffect(() => {
    getleaveList();
  }, [page]);

  const getleaveList = () => {
    utils
      .callAPI("get", `/leave/leave-request/team-list-Assign?type=other`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setleaveList(res);
          setHideAction(res?.result?.some((item) => item.status !== "0"));
        } else {
          setleaveList([]);
        }
      })
      .catch((err) => {
        setleaveList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSbmit = () => {
    if (formData.reason == "") {
      seterror("Please enter reason.");
      return;
    } else {
      seterror("");
    }
    SetIsSbmit(true);
    setShow(false);
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      bodyFormData.append("leave_id", crrentdata.id);
      bodyFormData.append("status", currentStatus);
      bodyFormData.append("auth_reason", formData.reason);

      utils
        .callAPI("post", `/leave/leave-request/approval`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            getleaveList();
            setCurrentStatus("");
            setCrrentdata({});
            SetIsSbmit(false);
            setFormData({
              reason: "",
            });
          }
        })
        .catch((err) => {});
    }
  }, [isSubmit]);
  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow ">
          {/* <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action"></div>
        </RB.Card.Header> */}
          <RB.Card.Body>
            {/* {utils.hasPermission(
            primaryMenuRes,
            "Leave Request",
            "leave_request.view"
          ) && ( */}
            <DataTable
              columns={columns}
              data={leaveList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={leaveList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              striped
              // subHeader
              // subHeaderComponent={subHeaderComponent}
            />
            {/* )} */}
          </RB.Card.Body>
        </RB.Card>
      </div>
      <RB.Modal show={show} onHide={handleClose} backdrop="static">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Row>
            <RB.Col md={12}>
              <div className="">
                <RB.FloatingLabel
                  label="Reason"
                  className="floating-label-fancy"
                >
                  <RB.Form.Control
                    as="textarea"
                    placeholder="Enter Reason"
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                    required
                    maxLength={500}
                    // className="textarea-lg"
                  />
                  {error && <div className="text-danger">{error}</div>}
                </RB.FloatingLabel>
              </div>
            </RB.Col>
          </RB.Row>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleClose}>
            Close
          </RB.Button>
          <RB.Button variant="primary" onClick={handleSbmit}>
            Save
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default OtherTeamLeave;
