import React, { useState, useEffect, useMemo } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import * as utils from "../../Utils/comman";
import DatePicker from "react-datepicker";
import { ArrowDown, CalendarEdit, Timer1 } from "iconsax-react";
import moment from "moment";
import axios from "axios";
import * as XLSX from "xlsx";

function AttendanceList() {
  const [attendanceList, setAttendanceList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [locations, setLocations] = useState({});

  useEffect(() => {
    getAttendanceList();
  }, [page, filterText, selectedDate]);

  const getAttendanceList = () => {
    utils
      .callAPI(
        "get",
        `/Attendance/dashboard-list?search=${filterText}&page=${page}&per_page=${countPerPage}&date=${
          selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : ""
        }`
      )
      .then(async (res) => {
        if (typeof res !== "undefined") {
          setAttendanceList(res);

          const locationPromises = {};

          res.result.forEach((record) => {
            if (record.attendace && record.attendace.length > 0) {
              record.attendace.forEach((time) => {
                if (time.latitude && time.longitude) {
                  locationPromises[time.id] = fetchAddress(
                    time.latitude,
                    time.longitude
                  );
                }
              });
            }
          });

          const resolvedLocations = await Promise.all(
            Object.values(locationPromises)
          );

          console.log("1", resolvedLocations);

          const newLocations = {};
          Object.keys(locationPromises).forEach((key, index) => {
            newLocations[key] = resolvedLocations[index];
          });

          console.log("2", newLocations);
          setLocations(newLocations);
        } else {
          setAttendanceList([]);
        }
      })
      .catch((err) => {
        setAttendanceList([]);
      });
  };

  const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const fetchAddress = async (latitude, longitude) => {
    try {
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&location_type=ROOFTOP&key=${googleMapApiKey}`
      );
      if (res.data.status === "OK") {
        return res.data.results[0].formatted_address;
      } else {
        return "Location not found.";
      }
    } catch (error) {
      return "Location not found.";
    }
  };
  const columns = [
    {
      name: (
        <div>
          Employee Id <br />
        </div>
      ),
      selector: (row) => row.employeeId,
      width: "140px",
    },
    {
      name: (
        <div>
          Employee Name <br />
        </div>
      ),
      selector: (row) => (
        <div>
          <div>{row.employee_name}</div>
          <div style={{ fontSize: "0.8rem" }}>{`(${row.roleName})`}</div>
        </div>
      ),
      width: "300px",
    },
    {
      name: (
        <div>
          Check type <br />
        </div>
      ),
      selector: (row) =>
        row?.attendace.length !== 0 ? row?.attendace[0]?.check_type : "",
      width: "150px",
    },
    {
      name: "Logs",
      center: true,
      selector: (row) => (
        <>
          {row.attendace && row.attendace.length > 0 ? (
            <>
              <RB.OverlayTrigger
                trigger="click"
                rootClose
                key="bottom"
                placement={"bottom"}
                overlay={
                  <RB.Popover id={`popover-positioned-bottom`}>
                    <RB.Popover.Body
                      className={`p-0 overflow-auto popover-scroll `}
                    >
                      <div className="list-group">
                        {row.leave_type === "first_half" && (
                          <div className="list-group-item d-flex align-items-center">
                            <RB.Row className="gx-3">
                              <RB.Col>
                                <div>
                                  <div className="d-flex align-items-center">
                                    <CalendarEdit
                                      color="green"
                                      variant="Bold"
                                    />
                                    <span className="text-sm ms-2">
                                      On Leave In First Half
                                    </span>
                                  </div>
                                </div>
                              </RB.Col>
                            </RB.Row>
                          </div>
                        )}
                        {row?.leave_type === "second_half" && (
                          <div className="list-group-item d-flex align-items-center">
                            <RB.Row className="gx-3">
                              <RB.Col>
                                {/* <div> */}
                                <div className="d-flex align-items-center text-center">
                                  <CalendarEdit variant="Bold" color="green" />
                                  <span className="text-sm ms-2">
                                    On Leave In Second Half
                                  </span>
                                </div>
                                {/* </div> */}
                              </RB.Col>
                            </RB.Row>
                          </div>
                        )}
                        {row.attendace
                          .sort((a, b) => new Date(a.date) - new Date(b.date))
                          .map((time, index) => {
                            if (index % 2 === 0) {
                              const checkOutIndex = index + 1;
                              const checkOutTime = row.attendace[checkOutIndex];
                              const checkInTime = moment(time.date).format(
                                "YYYY-MM-DD hh:mm A"
                              );
                              const formattedCheckOutTime = checkOutTime
                                ? moment(checkOutTime.date).format(
                                    "YYYY-MM-DD hh:mm A"
                                  )
                                : "Missing";

                              const checkInLocation = locations[time?.id];
                              const checkOutLocation =
                                locations[checkOutTime?.id];
                              return (
                                <div
                                  key={index}
                                  className="list-group-item d-flex align-items-center"
                                >
                                  <RB.Row className="gx-3">
                                    <RB.Col>
                                      <div>
                                        <div className="d-flex align-items-center">
                                          <i className="material-icons-outlined md16 text-success me-1">
                                            south_west
                                          </i>
                                          {/* <SouthWest className="md-sm"/> */}
                                          <span className="text-sm">
                                            {checkInTime}
                                          </span>
                                        </div>

                                        <span className="text-sm location-add-text">
                                          {checkInLocation}
                                        </span>
                                      </div>
                                    </RB.Col>
                                    <RB.Col className="col-auto p-0">
                                      <div className="mx-2">-</div>
                                    </RB.Col>
                                    <RB.Col>
                                      <div>
                                        <div className="d-flex align-items-center">
                                          <i class="material-icons-outlined md16 text-danger me-1">
                                            north_east
                                          </i>
                                          {/* <NorthEast /> */}
                                          <span className="text-sm">
                                            {formattedCheckOutTime}
                                          </span>
                                        </div>
                                        <span className="text-sm location-add-text">
                                          {checkOutLocation}
                                        </span>
                                      </div>
                                    </RB.Col>
                                  </RB.Row>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </div>
                    </RB.Popover.Body>
                  </RB.Popover>
                }
              >
                <button className="btn btn-flat">
                  <Timer1 />
                </button>
              </RB.OverlayTrigger>
            </>
          ) : (
            <>
              <div className="list-group-item text-center text-danger">
                <RB.Button className="btn" variant="outline-danger" size="sm">
                  Clock In/Out Missing
                </RB.Button>
              </div>
            </>
          )}
        </>
      ),
      width: "190px",
    },

    {
      name: (
        <div>
          Present/Absent <br />
        </div>
      ),
      selector: (row) => (
        <span
          className={
            row?.atten_type === "Present"
              ? "text-success fw-bold"
              : "text-danger fw-bold"
          }
        >
          {row?.atten_type}
        </span>
      ),
      width: "150px",
    },
    {
      name: (
        <div>
          Total Hours <br />
        </div>
      ),
      selector: (row) => row.working_hours,
    },
  ];

  const handleDownload = () => {
    utils
      .callAPI(
        "get",
        `/Attendance/attendance-download?date=${
          selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : ""
        }`
      )
      .then(async (res) => {
        if (typeof res !== "undefined") {
          console.log(res);
  
          const updatedData = await Promise.all(
            res.map(async (row) => {
              const clockInParts = row[6]?.split(" ");
              const clockOutParts = row[7]?.split(" ");

              const clockInAddress =
              clockInParts && clockInParts.length >= 4
                ? await fetchAddressfordownload(
                    clockInParts[2]?.split("-")[1],
                    clockInParts[3]?.split("-")[1]
                  )
                : "";

            const clockOutAddress =
              clockOutParts && clockOutParts.length >= 4
                ? await fetchAddressfordownload(
                    clockOutParts[2]?.split("-")[1],
                    clockOutParts[3]?.split("-")[1]
                  )
                : "";
  
                const newClockIn = clockInParts
                ? `${clockInParts[0]} ${clockInParts[1]}${
                    clockInAddress ? `(${clockInAddress})` : ""
                  }`
                : ""; 
                const newClockOut = clockOutParts
                ? `${clockOutParts[0]} ${clockOutParts[1]}${
                    clockOutAddress ? `(${clockOutAddress})` : ""
                  }`
                : "";

              return [
                row[0],
                row[1],
                row[2],
                row[3],
                row[4],
                row[5],
                newClockIn,
                newClockOut,
              ];
            })
          );
  
          generateCSV(updatedData);
        }
      })
      .catch((err) => {
        console.error("Error while downloading attendance", err);
      });
  };
  
  const fetchAddressfordownload = async (latitude, longitude) => {
    if (!latitude || !longitude) {
      return "";
    }

    console.log(latitude,longitude)
    try {
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&location_type=ROOFTOP&key=${googleMapApiKey}`
      );
      if (res.data.status === "OK") {
        return res.data.results[0].formatted_address;
      } else {
        return "Location not found.";
      }
    } catch (error) {
      return "Location not found.";
    }
  };
  
  function generateCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true,
    });
  
    const wb = XLSX.utils.book_new();
  
    XLSX.utils.book_append_sheet(wb, ws, "Emp Attendace");
  
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
  
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
  
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const fileName = selectedDate ? moment(selectedDate).format("DD-MM-YYYY") + "_attendance.csv" : moment().format("DD-MM-YYYY") + "_attendance.csv";
    
    link.download = fileName;
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  

  return (
    <>
      <RB.Row>
        <RB.Card>
          <RB.Card.Header>
            <h4>Employee Attendance</h4>
            <div className="card-header-action"></div>
          </RB.Card.Header>

          <RB.Card.Body>
            <RB.Row className="mb-3 justify-content-end">
              <RB.Col className="col-md-4 col-12 m-0">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  placeholderText="Select Date"
                  className="form-control"
                  maxDate={new Date()}
                  isClearable
                  dateFormat="dd-MM-yyyy"
                />
              </RB.Col>
              <RB.Col className="col-md-1 col-12 text-center">
                <RB.Button size="sm" variant="primary" onClick={handleDownload}>
                  <ArrowDown />
                </RB.Button>
              </RB.Col>
            </RB.Row>

            <DataTable
              columns={columns}
              data={attendanceList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={attendanceList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              striped
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              //   subHeader
              //   subHeaderComponent={subHeaderComponent}
            />
          </RB.Card.Body>
        </RB.Card>
      </RB.Row>
    </>
  );
}

export default AttendanceList;
