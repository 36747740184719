import React, { useState, useEffect, useCallback } from "react";
import * as RB from "react-bootstrap";
import avatarImg from "../assets/images/avatar-4.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../reducers/userProfileSlice";
import { fetchPrimaryMenu } from "../reducers/primaryMenuSlice";
import {
  ArrowCircleDown,
  Key,
  LogoutCurve,
  Notification,
  Profile,
  SearchZoomOut1,
  Setting2,
} from "iconsax-react";
import * as utils from "../Utils/comman";
import moment from "moment";

function Header() {
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const primaryMenuRes = useSelector((state) => state.primaryMenu.primaryMenu);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "access_token" && !event.newValue) {
        navigate("/");
      } else if (event.key === "access_token") {
        dispatch(fetchUserProfile());
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };
  useEffect(() => {
    if (!userProfile) {
      dispatch(fetchUserProfile());
    }
    if (!primaryMenuRes) {
      dispatch(fetchPrimaryMenu());
    }
  }, []);

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = () => {
    utils
      .callAPI("get", `/Notification/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setNotificationList(res.result);
          setNotificationCount(res.notification_counter);
        } else {
          setNotificationList([]);
        }
      })
      .catch((err) => {
        setNotificationList([]);
      });
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
  };

  const handleNotification = useCallback(
    (notification) => (event) => {
      event.preventDefault();

      if (notification.is_read === "0") {
        const bodyFormData = new FormData();
        bodyFormData.append("id", notification.id);
        utils
          .callAPI("post", `/Notification/set-read`, bodyFormData)
          .then(() => {
            getNotification();
            redirect(notification);
          })
          .catch((err) => {});
      } else {
        redirect(notification);
      }
    },
    [navigate, getNotification]
  );

  const redirect = (notification) => {
    const basePath = notification.extra.split("/").slice(0, -1).join("/");
    const isHRorAdmin = ["HR Manager", "Super Admin"].includes(
      userProfile?.user_role[0]?.roleName
    );

    switch (notification.type) {
      case "leave_request":
        if (isHRorAdmin && basePath.includes("team")) {
          navigate("/leave/leaves");
        } else {
          navigate(`${basePath}`);
        }
        break;
      case "resignation":
        if (isHRorAdmin && basePath.includes("team")) {
          navigate("/resignation/team-resignation-requests");
        } else {
          navigate(`${basePath}`);
        }
        break;
      case "new_employee":
        // navigate(`/employees/new-employee-list`);
        break;
      case "expense":
        if (
          userProfile?.user_role[0]?.roleName == "HR Manager" &&
          basePath.includes("team")
        ) {
          navigate("/expenses/all-expense");
        } else {
          navigate(`${basePath}`);
        }
        break;
      case "travel_request":
        navigate(`${basePath}`);
        break;
      case "regularization":
        navigate(`${basePath}`);
        break;
      default:
        break;
    }
  };

  const splitText = (text) => {
    const words = text.split(" ");
    const firstLineWords = words.slice(0, 8).join(" ");
    const secondLineWords = words.slice(8).join(" ");
    return { firstLineWords, secondLineWords };
  };

  return (
    <>
      <RB.Navbar
        expand="xl"
        className="navbar navbar-expand-lg main-navbar bg-white ps-2 pe-3 sticky-top"
      >
        <form
          className="form-inline d-flex align-items-center"
          onSubmit={handleSearchSubmit}
        >
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="offcanvas"
            // data-bs-target="#slidebarNaigation"
            // aria-controls="navbarSupportedContent"
            // aria-expanded="false"
            // aria-label="Toggle navigation"

            data-bs-target="#main-sidebar"
            aria-controls="main-sidebar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <ul className="navbar-nav me-3 d-none">
            <li>
              <a
                data-bs-toggle="collapse"
                data-bs-target="#slidebarNaigation"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>
            <li>
              <a
                data-bs-toggle="search"
                className="nav-link nav-link-lg d-sm-none"
              >
                <i className="fas fa-search"></i>
              </a>
            </li>
          </ul>
          {/* <h6 className="mb-0 d-none d-lg-block">Time To Build Better</h6> */}
          <div class="search-element ms-0">
            {/* <span class="search-icon">
							<i class="fas fa-search"></i>
						</span> */}
            <input
              class="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
              data-width="250"
            />
            <button class="btn" type="submit">
              <SearchZoomOut1 />
            </button>
            <div class="search-backdrop"></div>
            <div class="search-result">
              <div class="search-header">Search Suggestion</div>
              <div class="search-item">
                <a>Vura Suggestion</a>
                <a class="search-close">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="search-item">
                <a>Vura Suggestion</a>
                <a class="search-close">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="search-item">
                <a>Vura Suggestion</a>
                <a class="search-close">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="search-header">Search Result</div>
              <div class="search-item">
                <a>User Attendance</a>
              </div>
              <div class="search-item">
                <a>User Attendance</a>
              </div>
              <div class="search-item">
                <a>User Attendance</a>
              </div>
            </div>
          </div>
        </form>
        <ul className="navbar-nav  ms-auto d-flex align-items-center user-profile-module">
          <li className="dropdown dropdown-list-toggle ">
            <Link
              className="nav-link-lg message-toggle"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Notification />
              {notificationCount > 0 && (
                <span className="badge badge-danger text-sm">
                  <small>{notificationCount}</small>
                </span>
              )}
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-right notification-dorpdown"
              aria-labelledby="dropdownMenuButton1"
              style={{ left: "auto", right: "-180px" }}
            >
              <div className="dropdown-header d-flex justify-content-start">
                <h5>Notifications</h5>
              </div>
              <div className="dropdown-list-content dropdown-list-icons list-group notification-dorpdown-list">
                {notificationList
                  .filter((notification) => notification.is_read === "0") // Filter unread notifications
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by creation date
                  .map((notification) => {
                    const { firstLineWords, secondLineWords } = splitText(
                      notification.notification_text
                    );
                    return (
                      <Link
                        key={notification.id}
                        className={`dropdown-item dropdown-item-unread list-group-item ${
                          notification.is_read === "0" ? "bg-light" : ""
                        }`}
                        onClick={handleNotification(notification)}
                      >
                        <div className="dropdown-item-desc">
                          <b>{notification.title}</b>
                          <div className="mb-1"></div>
                          <p className="mb-1 text-break">
                            {firstLineWords}
                            {secondLineWords && (
                              <span>
                                <br />
                                {secondLineWords}
                              </span>
                            )}
                          </p>
                          <div className="time text-body text-sm">
                            {moment(notification.createdAt).format(
                              "YYYY-MM-DD hh:mm"
                            )}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>

              <div className="dropdown-footer text-end">
                <Link to="/notifications" className="text-underline">
                  View All <i className="fas fa-chevron-right"></i>
                </Link>
              </div>
            </ul>
          </li>
        </ul>

        {/* <ul className="navbar-nav navbar-right"> */}
        <div className="navbar-right ms-4 d-flex align-items-center user-profile-module">
          <span className="user-profile-name me-2">
            {userProfile?.firstName + " " + userProfile?.lastName}
          </span>
          <span
            className="avatar me-n2 avatar-circle text-dark fw-medium"
            data-initial="AD"
          >
            <img
              src={
                userProfile?.profilePic_path
                  ? userProfile?.profilePic_path
                  : avatarImg
              }
              className="img-fluid"
              alt="user"
            />
          </span>
          <RB.Dropdown>
            <RB.Dropdown.Toggle variant="light">
              <ArrowCircleDown size="20" color="#000000" />
            </RB.Dropdown.Toggle>

            <RB.Dropdown.Menu className="dropdown-menu-end">
              <RB.Dropdown.Item as={Link} to="/profile">
                <span className="user-icon">
                  <Profile />
                </span>
                <span className="user-icon-hover">
                  <Profile variant="Bold" />
                </span>
                <span className="ms-2">Profile</span>
              </RB.Dropdown.Item>
              <RB.Dropdown.Item as={Link} to="/change-password">
                <span className="user-icon">
                  <Setting2 />
                </span>
                <span className="user-icon-hover">
                  <Setting2 variant="Bold" />
                </span>
                <span className="ms-2">Change Password</span>
              </RB.Dropdown.Item>
              <RB.Dropdown.Item onClick={handleLogout} className="logout-link">
                <span className="user-icon">
                  <LogoutCurve />
                </span>
                <span className="user-icon-hover">
                  <LogoutCurve variant="Bold" />
                </span>
                <span className="ms-2">Logout</span>
              </RB.Dropdown.Item>
            </RB.Dropdown.Menu>
          </RB.Dropdown>

          {/* <RB.NavDropdown title="" align="end" className="text-dark">
						<ArrowCircleDown size="32" color="#FF8A65" />
						<RB.NavDropdown.Item
							as={Link}
							to="/profile"
							className="fs-6 d-flex align-items-center justify-content-start">
							<i class="material-icons-outlined">manage_accounts</i>
							<span className="ms-2">Profile</span>
						</RB.NavDropdown.Item>
						<RB.NavDropdown.Item
							as={Link}
							to="/change-password"
							className="fs-6 d-flex align-items-center justify-content-start">
							<span class="material-icons-outlined">lock_reset</span>
							<span className="ms-2">Change Password</span>
						</RB.NavDropdown.Item>
						<RB.NavDropdown.Item
							onClick={handleLogout}
							className="text-danger fs-6 d-flex align-items-center justify-content-start">
							<i className="material-icons-outlined">logout</i>
							<span className="ms-2">Logout</span>
						</RB.NavDropdown.Item>
					</RB.NavDropdown> */}
        </div>
      </RB.Navbar>
      {/* </ul> */}
    </>
  );
}
export default Header;
