import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  AccountBalanceWalletOutlined,
  Delete,
  EventAvailable,
  EventNote,
  ExpandMore,
  MoreHoriz,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEmpSalary } from "../../reducers/employeeSalarySlice";
import { useSelector } from "react-redux";
import useCommandRRedirect from "../../hooks/useCommandRRedirect";
import { ArrowDown, More, PercentageCircle, Trash } from "iconsax-react";
import * as XLSX from "xlsx";

function List(props) {
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const [empList, setEmpList] = useState([]);
  const [page, setPage] = useState(1);
  const [countPerPage, setcountPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // useCommandRRedirect("/dashboard");
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [role, setRole] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectedRole, setselectedRole] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState([]);
  const [isExcelDownload, setIsExcelDownload] = useState(false);

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  useEffect(() => {
    getRoleList();
    getDepartmentList();
  }, []);

  const getRoleList = () => {
    utils
      .callAPI("get", `/role/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setRole(res.result);
        } else {
          setRole([]);
        }
      })
      .catch((err) => {
        setRole([]);
      });
  };

  const getDepartmentList = () => {
    utils
      .callAPI("get", `/department/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setDepartment(res.result);
        } else {
          setDepartment([]);
        }
      })
      .catch((err) => {
        setDepartment([]);
      });
  };

  const getRoleName = (roleId) => {
    const roleItem = role.find((r) => r.id === roleId);
    return roleItem ? roleItem.roleName : "N/A";
  };

  const getDepartmentName = (departmentId) => {
    const departmentItem = department.find((d) => d.id === departmentId);
    return departmentItem ? departmentItem.department_name : "N/A";
  };

  const columns = [
    {
      name: "Employee Id",
      selector: (row) => row.employeeId,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.middleName} ${row.lastName}`,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Department",
      selector: (row) => getDepartmentName(row.departmentId),
    },
    {
      name: "Designation",
      selector: (row) => getRoleName(row.roleId),
      minWidth: "160px",
    },
    {
      name: "Joinnig Date",
      selector: (row) => moment(row.join_date).format("DD-MM-yyyy"),
    },
    {
      name: "Mobile No.",
      selector: (row) => row.mobile_no,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0">
                  <div className="list-group">
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Employee",
                      "employee.edit"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={handelEdit(row)}
                      >
                        <EditIcon /> Edit
                      </Link>
                    )}
                    {utils.hasPermission(
                      primaryMenuRes,
                      "Employee",
                      "employee.salary"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSalary(row)();
                        }}
                      >
                        <AccountBalanceWalletOutlined /> Employee Salary
                      </Link>
                    )}

                    {/* {utils.hasPermission(
                      primaryMenuRes,
                      "Salary",
                      "salary.view"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleTds(row)();
                        }}
                      >
                        <AccountBalanceWalletOutlined /> TDS
                      </Link>
                    )} */}

                    {/* <Link
                      className="list-group-item list-group-item-action"
                      onClick={(e) => {
                        e.preventDefault();
                        handleEmpLeave(row)();
                      }}
                    >
                      <EventNote /> Leave Balance
                    </Link> */}

                    {utils.hasPermission(
                      primaryMenuRes,
                      "Employee",
                      "employee.attendance"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEmpAttendance(row)();
                        }}
                      >
                        <EventAvailable /> Employee Attendance
                      </Link>
                    )}

                    {utils.hasPermission(
                      primaryMenuRes,
                      "Employee",
                      "employee.TDS"
                    ) && (
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEmpTds(row)();
                        }}
                      >
                        <PercentageCircle /> Employee TDS
                      </Link>
                    )}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <Link className="btn btn-flat">
              <More />
            </Link>
          </RB.OverlayTrigger>
          {utils.hasPermission(
            primaryMenuRes,
            "Employee",
            "employee.delete"
          ) && (
            <Link
              className="btn btn-flat ms-2"
              onClick={showDelete(row)}
              title="Delete"
            >
              <Trash />
            </Link>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getEmployeeList();
  }, [page, filterText, selectedDepartment, selectedRole]);

  const getEmployeeList = () => {
    utils
      .callAPI(
        "get",
        `/employee/list?search=${filterText}&page=${page}&per_page=${countPerPage}&departmentId=${selectedDepartment}&designationId=${selectedRole}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setEmpList(res);
        } else {
          setEmpList([]);
        }
      })
      .catch((err) => {
        setEmpList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Delete Employee",
      text: "Once deleted, your data will be permanently removed and cannot be recovered. Are you sure you want to delete?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI(
            "get",
            `/employee/delete?id=${row.id}&role_id=${row.role_edit_id}`,
            ""
          )
          .then((res) => {
            if (typeof res !== "undefined") {
              getEmployeeList();
            }
          })
          .catch((err) => {});
      }
    });
  });
  const handleSalary = useCallback((row) => async () => {
    dispatch(setEmpSalary(row));

    navigte(`/employee/salary?id=${row.employeeId}`);
  });

  const handleTds = useCallback((row) => async () => {
    //dispatch(setEmpSalary(row));

    navigte(`/employee/tds?id=${row.employeeId}`);
  });

  const handleEmpLeave = useCallback((row) => async () => {
    navigte(`/leave/leave-balance?id=${row.userId}`);
  });

  const handleEmpAttendance = useCallback((row) => async () => {
    navigte(`/attendances?id=${row.id}`);
  });

  const handleEmpTds = useCallback((row) => async () => {
    navigte(`/employee/tds?id=${row.id}`);
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columnHeaderMapping = {
    employeeId: "Employee Id",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    email: "Email",
    personal_email: "Personal Email",
    birth_date: "Birth Date",
    join_date: "Join Date",
    location: "Location",
    training_schedule: "Training Schedule",
    emp_handbook: "Employee Handbook",
    emergency_contact: "Emergency Contact",
    emergency_contact_relation: "Emergency Contact Relation",
    bank_name: "Bank Name",
    bank_branch: "Bank Branch",
    bank_account_no: "Bank Account No",
    bank_ifsc_code: "Bank IFSC Code",
    probation_period: "Probation Period",
    probation_status: "Probation Status",
    panNo: "PAN No",
    training_time: "Training Time",
    pf_uan_no: "PF UAN No",
    courierAddress: "Courier Address",
    motherName: "Mother's Name",
    gender: "Gender",
    maritalStatus: "Marital Status",
    bloodGroup: "Blood Group",
    emergencyContactNo2: "Emergency Contact No 2",
    emergencyContactNo2Name: "Emergency Contact No 2 Name",
    emergencyRelationship: "Emergency Relationship",
    emergencyAddress: "Emergency Address",
    roleName: "Designation",
    department_name: "Department Name",
    reporting_name: "Reporting Name",
    group_name: "Group Name",
  };

  const fieldsToExclude = [
    "id",
    "userId",
    "employee_contact_no",
    "blood_group",
    "last_company",
    "roleId",
    "departmentId",
    "reporting_manager",
    "onboard_plan",
    "welcome_kit",
    "it_setup",
    "doc_submit",
    "holiday_group",
    "check_address",
    "isActive",
    "createdAt",
    "updatedAt",
    "deletedAt",
    "role_edit_id",
    "profilePath",
    "employee_salary",
    "employee_document",
  ];
  const handleDownload = () => {
    utils
      .callAPI(
        "get",
        `/employee/list?search=${filterText}&page=${0}&per_page=${0}&departmentId=${selectedDepartment}&designationId=${selectedRole}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          // setEmpList(res);
          const filteredData = res?.result?.map((item) => {
            const filteredItem = {};
            Object.keys(item).forEach((key) => {
              if (!fieldsToExclude.includes(key)) {
                filteredItem[columnHeaderMapping[key] || key] = item[key];
              }
            });
            return filteredItem;
          });

          const ws = XLSX.utils.json_to_sheet(filteredData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Employees");
          // XLSX.writeFile(wb, "Employee List" + ".xlsx");
          const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
          const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
 
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Employee_List.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // setEmpList([]);
        }
      })
      .catch((err) => {
        // setEmpList([]);
      });
  };

  
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }
  
  const handleClearFilter = () => {
    setselectedDepartment("");
    setselectedRole("");
  };
  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action">
            {utils.hasPermission(
              primaryMenuRes,
              "Employee",
              "employee.create"
            ) && (
              <RB.Button className="btn btn-primary" onClick={handleAddClick}>
                Add Employee
              </RB.Button>
            )}
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Row>
            <RB.Col md={4}>
              <RB.FloatingLabel
                label="Department"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  as="select"
                  name="departmentId"
                  value={selectedDepartment}
                  onChange={(e) => {
                    setselectedDepartment(e.target.value);
                  }}
                  required
                >
                  <option value="">Select Department</option>
                  {department.map((deptItem) => (
                    <option key={deptItem.id} value={deptItem.id}>
                      {deptItem.department_name}
                    </option>
                  ))}
                </RB.Form.Control>
              </RB.FloatingLabel>
            </RB.Col>
            <RB.Col md={4}>
              <RB.FloatingLabel
                label="Designation"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  as="select"
                  name="roleId"
                  value={selectedRole}
                  onChange={(e) => {
                    setselectedRole(e.target.value);
                  }}
                  required
                >
                  <option value="">Select Designation</option>
                  {role.map((roleItem) => (
                    <option key={roleItem.id} value={roleItem.id}>
                      {roleItem.roleName}
                    </option>
                  ))}
                </RB.Form.Control>
              </RB.FloatingLabel>
            </RB.Col>
            <RB.Col md={2} className="text-start mt-3">
              <Link className="ms-2 text-underline" onClick={handleClearFilter}>
                Clear
              </Link>
            </RB.Col>
            <RB.Col md={2} className="text-end mt-3">
              {utils.hasPermission(
                primaryMenuRes,
                "Employee",
                "employee.download"
              ) && (
                <RB.Button
                  className="ms-2"
                  size="sm"
                  variant="primary"
                  onClick={handleDownload}
                  disabled={empList?.result?.length == 0}
                >
                  <ArrowDown />
                </RB.Button>
              )}
            </RB.Col>
          </RB.Row>

          {utils.hasPermission(primaryMenuRes, "Employee", "employee.view") && (
            <DataTable
              columns={columns}
              data={empList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={empList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              striped
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
