import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import {
  AccountBalanceWalletOutlined,
  Delete,
  EventNote,
  NorthEast,
  PendingActions,
  Search,
  SouthWest,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { clockIn, clockOut } from "../../reducers/userAttendanceSlice";
import { fetchUserProfile } from "../../reducers/userProfileSlice";
import DatePicker from "react-datepicker";
import { Add, ArrowDown, CalendarEdit, Timer1 } from "iconsax-react";
import { update } from "lodash";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useGeolocated } from "react-geolocated";
import axios from "axios";

function List(props) {
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [attendace, setAttendace] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startError, setStartError] = useState(false);
  const clockStatus = useSelector((state) => state.clockStatus?.clockStatus);
  const checkTypeRedux = useSelector((state) => state.clockStatus?.checkType);
  // const [clockStatus, setClockStatus] = useState("in");
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const [dateFilter, setDateFilter] = useState(false);
  const [checkType, setCheckType] = useState(
    checkTypeRedux ? checkTypeRedux : "local"
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState([]);
  const [empData, setEmpData] = useState([]);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState(null);

  const [locations, setLocations] = useState({});

  const {
    coords,
    getPosition,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
    watchLocationPermissionChange: true,
  });

  const columns = [
    {
      name: "Attendance Date",
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
    },
    {
      name: "Working Hours",
      selector: (row) => row.working_hours,
    },
    {
      name: "Check type",
      selector: (row) =>
        row?.check_time.length !== 0 ? row?.check_time[0]?.check_type : "",
    },
    {
      name: "Regularization Status",
      selector: (row) => row.update_status,
    },
    {
      name: "Logs",
      center: true,
      selector: (row) => (
        <>
          {row.check_time && row.check_time.length > 0 ? (
            <>
              <RB.OverlayTrigger
                trigger="click"
                rootClose
                key="bottom"
                placement={`${id ? "bottom-end" : "bottom"}`}
                overlay={
                  <RB.Popover id={`popover-positioned-bottom`}>
                    <RB.Popover.Body
                      className={`p-0 overflow-auto popover-scroll ${
                        id ? "attendace-popover-body" : ""
                      }`}
                    >
                      <div className="list-group">
                        {row.leave_type === "first_half" && (
                          <div className="list-group-item d-flex align-items-center">
                            <RB.Row className="gx-3">
                              <RB.Col>
                                <div>
                                  <div className="d-flex align-items-center">
                                    <CalendarEdit
                                      color="green"
                                      variant="Bold"
                                    />
                                    <span className="text-sm ms-2">
                                      On Leave In First Half
                                    </span>
                                  </div>
                                </div>
                              </RB.Col>
                            </RB.Row>
                          </div>
                        )}
                        {row?.leave_type === "second_half" && (
                          <div className="list-group-item d-flex align-items-center">
                            <RB.Row className="gx-3">
                              <RB.Col>
                                {/* <div> */}
                                <div className="d-flex align-items-center text-center">
                                  <CalendarEdit variant="Bold" color="green" />
                                  <span className="text-sm ms-2">
                                    On Leave In Second Half
                                  </span>
                                </div>
                                {/* </div> */}
                              </RB.Col>
                            </RB.Row>
                          </div>
                        )}
                        {row.check_time
                          .sort((a, b) => new Date(a.date) - new Date(b.date))
                          .map((time, index) => {
                            if (index % 2 === 0) {
                              const checkOutIndex = index + 1;
                              const checkOutTime =
                                row.check_time[checkOutIndex];
                              const checkInTime = moment(time.date).format(
                                "YYYY-MM-DD hh:mm A"
                              );
                              const formattedCheckOutTime = checkOutTime
                                ? moment(checkOutTime.date).format(
                                    "YYYY-MM-DD hh:mm A"
                                  )
                                : "Missing";

                              const checkInLocation = locations[time?.id];
                              const checkOutLocation =
                                locations[checkOutTime?.id];
                              return (
                                <div
                                  key={index}
                                  className="list-group-item d-flex align-items-center"
                                >
                                  <RB.Row className="gx-3">
                                    <RB.Col>
                                      <div>
                                        <div className="d-flex align-items-center">
                                          <i className="material-icons-outlined md16 text-success me-1">
                                            south_west
                                          </i>
                                          {/* <SouthWest className="md-sm"/> */}
                                          <span className="text-sm">
                                            {checkInTime}
                                          </span>
                                        </div>

                                        {id && (
                                          <span className="text-sm location-add-text">
                                            {checkInLocation}
                                          </span>
                                        )}
                                      </div>
                                    </RB.Col>
                                    <RB.Col className="col-auto p-0">
                                      <div className="mx-2">-</div>
                                    </RB.Col>
                                    <RB.Col>
                                      <div>
                                        <div className="d-flex align-items-center">
                                          <i class="material-icons-outlined md16 text-danger me-1">
                                            north_east
                                          </i>
                                          {/* <NorthEast /> */}
                                          <span className="text-sm">
                                            {formattedCheckOutTime}
                                          </span>
                                        </div>
                                        {id && (
                                          <span className="text-sm location-add-text">
                                            {checkOutLocation}
                                          </span>
                                        )}
                                      </div>
                                    </RB.Col>
                                  </RB.Row>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </div>
                    </RB.Popover.Body>
                  </RB.Popover>
                }
              >
                <button className="btn btn-flat">
                  <Timer1 />
                </button>
              </RB.OverlayTrigger>
            </>
          ) : (
            <>
              <div className="list-group-item text-center text-danger">
                <RB.Button className="btn" variant="outline-danger" size="sm">
                  {row.leave_day}
                  {row.leave_type === "first_half" && " (First Half Leave)"}
                  {row.leave_type === "second_half" && " (Second Half Leave)"}
                </RB.Button>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          {!id && (
            <>
              {!row.update_status && (
                <>
                  {!row.is_leave == true && (
                    <>
                      {utils.hasPermission(
                        primaryMenuRes,
                        "Attendance",
                        "attendance.regularize"
                      ) && (
                        <RB.Button
                          className="btn btn-underline"
                          onClick={() => handleRegularize(row)}
                        >
                          Regularize
                        </RB.Button>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ),
      omit: id,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.leave_day,
      style: {
        backgroundColor: "#d5e2f7",
      },
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString("en-US", { hour12: true }));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userProfile?.check_time === "checkIn") {
      // setClockStatus("out");
      dispatch(clockOut());
    } else {
      // setClockStatus("in");
      dispatch(clockIn());
    }
  }, [userProfile]);

  useEffect(() => {
    getAttendanceList();
  }, [page, id]);

  const getAttendanceList = () => {
    let apiUrl = `/Attendance/list?page=${page}&per_page=${countPerPage}`;
    const idToFetch = id || queryParams.get("id");
    if (startDate && endDate) {
      apiUrl += `&sdate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&edate=${moment(endDate).format("YYYY-MM-DD")}`;
    }
    // if (startDate && endDate) {
    //   apiUrl += `&sdate=${moment(startDate).format(
    //     "YYYY-MM-DD"
    //   )}&edate=${moment(endDate).format("YYYY-MM-DD")}`;
    // }
    if (idToFetch) {
      apiUrl += `&employeeId=${idToFetch}`;
    }

    utils
      .callAPI("get", apiUrl)

      .then(async (res) => {
        if (typeof res !== "undefined") {
          setAttendace(res.attendance_data);
          setEmpData(res.employee_data);

          const locationPromises = {};

          res.attendance_data.forEach((record) => {
            if (record.check_time && record.check_time.length > 0) {
              record.check_time.forEach((time) => {
                if (time.latitude && time.longitude) {
                  locationPromises[time.id] = fetchAddress(
                    time.latitude,
                    time.longitude
                  );
                }
              });
            }
          });

          const resolvedLocations = await Promise.all(
            Object.values(locationPromises)
          );

          console.log("1", resolvedLocations);

          const newLocations = {};
          Object.keys(locationPromises).forEach((key, index) => {
            newLocations[key] = resolvedLocations[index];
          });

          console.log("2", newLocations);
          setLocations(newLocations);

          setDateFilter(false);
          // setStartDate(null);
          // setEndDate(null);
          setDateFilter(false);
          // setStartDate(null);
          // setEndDate(null);
          // const transformedData = res.check_time.map((check) => ({
          //   date: res.date,
          //   checkInTime: check.status === "checkIn" ? check.date : null,
          //   checkOutTime: check.status === "checkOut" ? check.date : null,
          //   working_hours: res.working_hours,
          // }));

          // setExcelData(transformedData);
        } else {
          setAttendace([]);
          setDateFilter(false);
          setStartDate(null);
          setEndDate(null);
          setDateFilter(false);
          setStartDate(null);
          setEndDate(null);
        }
      })
      .catch((err) => {
        setAttendace([]);
        setDateFilter(false);
        setStartDate(null);
        setEndDate(null);
        setDateFilter(false);
        setStartDate(null);
        setEndDate(null);
      });
  };

  const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const fetchAddress = async (latitude, longitude) => {
    try {
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&location_type=ROOFTOP&key=${googleMapApiKey}`
      );
      if (res.data.status === "OK") {
        return res.data.results[0].formatted_address;
      } else {
        return "Location not found.";
      }
    } catch (error) {
      return "Location not found.";
    }
  };

  const handleClick = () => {
    const dateTime = new Date().toLocaleString();

    if (clockStatus === "in") {
      dispatch(clockIn(checkType));
      submitAttendance("checkIn", dateTime);
    } else {
      dispatch(clockIn(checkType));
      submitAttendance("checkOut", dateTime);
    }
  };

  const submitAttendance = (status, dateTime) => {
    var bodyFormData = new FormData();
    // bodyFormData.append("userId", userProfile.id);
    bodyFormData.append("status", status);
    bodyFormData.append("check_type", checkType);
    bodyFormData.append("latitude", coords.latitude);
    bodyFormData.append("longitude", coords.longitude);
    bodyFormData.append("date", moment(dateTime).format("YYYY-MM-DD HH:mm:ss"));

    utils
      .callAPI("post", `/Attendance/add`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          // setClockStatus(status === "checkIn" ? "out" : "in");
          dispatch(clockStatus === "in" ? clockOut() : clockIn());
          if (clockStatus === "out") {
            getAttendanceList();
          }
          dispatch(fetchUserProfile());
        }
      })
      .catch((err) => {});
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setDateFilter(false);
  };

  useEffect(() => {
    if (!startDate && !endDate) {
      getAttendanceList();
    }
  }, [startDate, endDate]);

  const handleFilter = () => {
    setDateFilter(true);
  };

  const handleEndDateChange = (date) => {
    if (!startDate) {
      setStartError(true);
      return;
    }
    setEndDate(date);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setStartError(false);
  };

  useEffect(() => {
    if (dateFilter) {
      getAttendanceList();
    }
  }, [dateFilter]);

  const [newTimeLog, setNewTimeLog] = useState([]);
  const [currentLogDate, setCurrentLogDate] = useState(null);

  const handleRegularize = (log) => {
    setCurrentLogDate(log.date);
    const sortedLogs = log.check_time
      ? log.check_time.sort((a, b) => new Date(a.date) - new Date(b.date))
      : [];

    if (sortedLogs.length == 0) {
      handleAddTime();
      setShowModal(true);
      return;
    }

    const groupedLogs = [];

    for (let i = 0; i < sortedLogs.length; i++) {
      if (sortedLogs[i].status === "checkIn") {
        const checkInLog = sortedLogs[i];
        const checkOutLog =
          sortedLogs[i + 1] && sortedLogs[i + 1].status === "checkOut"
            ? sortedLogs[i + 1]
            : null;

        groupedLogs.push({
          checkIn: checkInLog,
          checkOut: checkOutLog,
        });

        if (checkOutLog) {
          i++;
        }
      }
    }
    setSelectedLog(groupedLogs);
    setShowModal(true);
  };

  const transformLogs = (logs) => {
    const transformedData = {
      employeeId: logs[0]?.checkIn?.employeeId,
      date: currentLogDate,
      check_time: [],
    };

    logs.forEach((log) => {
      const { checkIn, checkOut } = log;

      if (checkIn) {
        transformedData.check_time.push({
          id: checkIn.id,
          employeeId: checkIn.employeeId,
          check_type: checkIn.check_type,
          status: checkIn.status,
          date: checkIn.date,
          new_time: checkIn.new_time
            ? moment(checkIn.new_time).format("YYYY-MM-DD HH:mm:ss")
            : null,
        });
      }

      if (checkOut) {
        transformedData.check_time.push({
          id: checkOut.id,
          employeeId: checkOut.employeeId,
          check_type: checkOut.check_type,
          status: checkOut.status,
          date: checkOut.date,
          new_time: checkOut.new_time
            ? moment(checkOut.new_time).format("YYYY-MM-DD HH:mm:ss")
            : null,
        });
      }
    });

    return transformedData;
  };

  const handleSaveRegularize = () => {
    const transformedLogs = transformLogs(newTimeLog);

    utils
      .callAPI("post", `/Attendance/update`, transformedLogs)
      .then((res) => {
        if (typeof res !== "undefined") {
          getAttendanceList();
          setShowModal(false);
          setSelectedLog([]);
        }
      })
      .catch((err) => {});
  };

  const handleAddTime = () => {
    const newLog = {
      checkIn: {
        id: "",
        employeeId: userProfile?.employeeId,
        check_type: checkType,
        status: "checkIn",
        date: currentLogDate
          ? `${moment(currentLogDate).format("YYYY-MM-DD")} 00:00:00`
          : null,
      },
      checkOut: {
        id: "",
        employeeId: userProfile?.employeeId,
        check_type: checkType,
        status: "checkOut",
        date: currentLogDate
          ? `${moment(currentLogDate).format("YYYY-MM-DD")} 00:00:00`
          : null,
      },
    };

    setSelectedLog([...selectedLog, newLog]);
  };

  const handleEditTime = (index, type, newTime) => {
    const updatedLogs = [...selectedLog];
    const currentDate = moment(currentLogDate).format("YYYY-MM-DD");

    const formatDateTime = (date, time) => {
      return `${moment(date).format("YYYY-MM-DD")} ${moment(time).format(
        "HH:mm:ss"
      )}`;
    };

    if (type === "checkIn") {
      const newCheckInTime = newTime
        ? formatDateTime(currentLogDate, newTime)
        : null;

      if (!updatedLogs[index].checkIn.id) {
        updatedLogs[index].checkIn.new_time = newCheckInTime;
        updatedLogs[index].checkIn.date = newCheckInTime;
      } else {
        if (newTime && newCheckInTime !== updatedLogs[index].checkIn.new_time) {
          updatedLogs[index].checkIn.new_time = newCheckInTime;
        }
      }
    } else if (type === "checkOut") {
      const newCheckOutTime = newTime
        ? formatDateTime(currentLogDate, newTime)
        : null;

      if (!updatedLogs[index].checkOut) {
        updatedLogs[index].checkOut = {
          id: "",
          employeeId: userProfile?.employeeId,
          check_type: checkType,
          status: "checkOut",
          date: newCheckOutTime,
          new_time: newCheckOutTime,
        };
      } else {
        if (!updatedLogs[index].checkOut.id) {
          updatedLogs[index].checkOut.new_time = newCheckOutTime;
          updatedLogs[index].checkOut.date = newCheckOutTime;
        } else {
          updatedLogs[index].checkOut.new_time = newCheckOutTime;
        }
      }
    }

    setNewTimeLog(updatedLogs);
    setSelectedLog(updatedLogs);
  };

  const handleDownload = () => {
    const formattedData = [];

    attendace.forEach((day, index) => {
      if (Array.isArray(day.check_time) && day.check_time.length > 0) {
        const checkInTimes = day.check_time.filter(
          (time) => time.status === "checkIn"
        );
        const checkOutTimes = day.check_time.filter(
          (time) => time.status === "checkOut"
        );

        for (let i = 0; i < checkInTimes.length; i++) {
          const checkInTime = checkInTimes[i];
          const checkOutTime = checkOutTimes[i] || null;

          const rowData = {
            "Attendance Date": checkInTime.date,
            "Working Hours": day.working_hours || day.leave_day,
            "Check In Time": moment(checkInTime.date).format("hh:mm A"),
            "Check Out Time": checkOutTime
              ? moment(checkOutTime.date).format("hh:mm A")
              : "",
          };

          formattedData.push(rowData);
        }
      } else if (day.leave_day) {
        const leaveDayData = {
          "Attendance Date": day.date,
          "Working Hours": day.working_hours || day.leave_day,
          "Check In Time": "",
          "Check Out Time": "",
        };

        if (
          index + 1 < attendace.length &&
          !attendace[index + 1].working_hours
        ) {
          const nextDay = attendace[index + 1];
          if (
            Array.isArray(nextDay.check_time) &&
            nextDay.check_time.length > 0
          ) {
            const checkInTimes = nextDay.check_time.filter(
              (time) => time.status === "checkIn"
            );
            const checkOutTimes = nextDay.check_time.filter(
              (time) => time.status === "checkOut"
            );

            for (let i = 0; i < checkInTimes.length; i++) {
              const checkInTime = checkInTimes[i];
              const checkOutTime = checkOutTimes[i] || null;

              leaveDayData["Check In Time"] += `, ${moment(
                checkInTime.date
              ).format("hh:mm A")}`;
              leaveDayData["Check Out Time"] += checkOutTime
                ? `, ${moment(checkOutTime.date).format("hh:mm A")}`
                : "";
            }
          }
        }

        formattedData.push(leaveDayData);
      }
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);

    // Add employee info
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["Employee Name", `${empData.employee_name}`]],
      { origin: "A1" }
    );
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["Employee Code", `${empData.employeeId}`]],
      { origin: "A2" }
    );

    // Add a blank row
    XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: "A3" });

    // Set headers and data
    const headers = Object.keys(formattedData[0]);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A4" });
    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: "A5",
      skipHeader: true,
    });

    // Style headers
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = XLSX.utils.encode_cell({ r: 3, c: C });
      if (!worksheet[cell_address])
        worksheet[cell_address] = { t: "s", v: headers[C] };
      worksheet[cell_address].s = { font: { bold: true } };
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance");

    for (let R = 4; R <= range.e.r; ++R) {
      const cell_address = XLSX.utils.encode_cell({ r: R, c: 1 });
      if (
        worksheet[cell_address] &&
        !/^\d{2}:\d{2}:\d{2}$/.test(worksheet[cell_address].v)
      ) {
        worksheet[cell_address].s = {
          font: { bold: true },
          alignment: { horizontal: "middle" },
        };
        worksheet[`B${R + 1}`] = {
          t: "s",
          v: `${worksheet[cell_address].v}`,
          s: { alignment: { horizontal: "middle" } },
        };
        worksheet[`C${R + 1}`] = {
          t: "s",
          v: `${worksheet[cell_address].v}`,
          s: { alignment: { horizontal: "middle" } },
        };
        worksheet[`D${R + 1}`] = {
          t: "s",
          v: `${worksheet[cell_address].v}`,
          s: { alignment: { horizontal: "middle" } },
        };
        worksheet[`!merges`] = worksheet[`!merges`] || [];
        worksheet[`!merges`].push({ s: { r: R, c: 1 }, e: { r: R, c: 3 } });
      }
    }

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, `${empData.employee_name}_${empData.employeeId}.xlsx`);
  
  };

  return (
    <>
      {!id && (
        <RB.Row>
          <RB.Col md={4}>
            <RB.Card className="mb-2">
              <RB.Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="current-time mb-0">CURRENT TIME</p>
                    <div className="time">
                      <span className="fs-2">
                        {currentTime.split(":").slice(0, 2).join(":")}
                      </span>
                      :
                      <span className="fs-6">
                        {currentTime.split(":").slice(2).join(":")}
                      </span>
                    </div>
                  </div>

                  {utils.hasPermission(
                    primaryMenuRes,
                    "Attendance",
                    "attendance.create"
                  ) && (
                    <div className="text-center">
                      <RB.Button
                        variant={clockStatus === "in" ? "dark" : "danger"}
                        onClick={handleClick}
                      >
                        {clockStatus === "in" ? "Clock-In" : "Clock-Out"}
                      </RB.Button>
                      <div className="travel-clock mt-2">
                        <RB.Form>
                          <RB.Form.Group>
                            <RB.Form.Check
                              inline
                              label="Local"
                              type="radio"
                              id="local-radio"
                              checked={checkType === "local"}
                              onChange={() => setCheckType("local")}
                              disabled={clockStatus === "out"}
                            />
                            <RB.Form.Check
                              inline
                              label="Travel"
                              type="radio"
                              id="travel-radio"
                              checked={checkType === "travel"}
                              onChange={() => setCheckType("travel")}
                              disabled={clockStatus === "out"}
                            />
                             <RB.Form.Check
                              inline
                              label="WFH"
                              type="radio"
                              id="WFH-radio"
                              checked={checkType === "WFH"}
                              onChange={() => setCheckType("WFH")}
                              disabled={clockStatus === "out"}
                            />
                          </RB.Form.Group>
                        </RB.Form>
                      </div>
                    </div>
                  )}
                </div>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
        </RB.Row>
      )}
      {id && (
        <>
          <RB.Card>
            <RB.Card.Body>
              <RB.Row className="text-center align-items-center">
                <RB.Col md={2}>
                  <img
                    src={empData.profilePic_path}
                    alt="Profile Pic"
                    className="img-fluid rounded-circle"
                    style={{ maxWidth: "100%", height: "90px" }}
                  />
                </RB.Col>
                <RB.Col md={2}>
                  <h5>{empData.employee_name}</h5>
                  <p>{empData.roleName}</p>
                </RB.Col>
                <RB.Col md={2}>
                  <h6>Employee ID</h6>
                  <p>{empData.employeeId}</p>
                </RB.Col>
                <RB.Col md={3}>
                  <h6>Joining Date</h6>
                  <p>{empData.join_date}</p>
                </RB.Col>
                <RB.Col md={3}>
                  <h6>Department</h6>
                  <p>{empData.department_name}</p>
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </>
      )}
      <div className="section-body">
        <RB.Card className="card shadow ">
          <RB.Card.Header>{/* <h4>{id ? "" : ""}</h4> */}</RB.Card.Header>

          <RB.Card.Body>
            {utils.hasPermission(
              primaryMenuRes,
              "Attendance",
              "attendance.view"
            ) && (
              <>
                <RB.Row>
                  <RB.Col md={12} className="mb-3">
                    <div className="row g-3 align-items-center" text-center>
                      <div className="col-sm-auto d-flex justify-content-center">
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd-MM-yyyy"
                          className={`form-control range-datepicker-input ${
                            startError ? "date-border-danger" : ""
                          }`}
                          placeholderText="Select Start Date"
                          maxDate={new Date()}
                          required
                        />
                      </div>
                      <div className="col-12 col-sm-auto text-center">To</div>
                      <div className="col-sm-auto d-flex justify-content-center">
                        <DatePicker
                          selected={endDate}
                          onChange={handleEndDateChange}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="form-control range-datepicker-input"
                          placeholderText="Select End Date"
                          required
                        />
                      </div>
                      <div className="col-sm-auto d-flex justify-content-center align-items-center">
                        <RB.Button
                          className="ms-2"
                          variant="primary"
                          onClick={handleFilter}
                          disabled={!startDate || !endDate}
                        >
                          Find
                        </RB.Button>
                        <Link
                          className="ms-2 text-underline"
                          onClick={handleReset}
                        >
                          Reset
                        </Link>
                      </div>
                      {id && (
                        <div className="col text-end">
                          <div></div>
                          <RB.Button
                            size="sm"
                            variant="primary"
                            onClick={handleDownload}
                            disabled={attendace?.length === 0}
                          >
                            <ArrowDown />
                          </RB.Button>
                        </div>
                      )}
                    </div>
                  </RB.Col>
                  <RB.Col md={6}></RB.Col>
                </RB.Row>

                <DataTable
                  conditionalRowStyles={conditionalRowStyles}
                  columns={columns}
                  data={attendace}
                  highlightOnHover
                  responsive
                  striped
                  pagination
                  paginationServer
                  paginationTotalRows={attendace.total}
                  paginationPerPage={countPerPage}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => setPage(page)}
                  persistTableHead
                  customStyles={{
                    head: {
                      style: {
                        fontSize: "0.875rem", // Adjust the font size as needed
                        fontWeight: "bold", // Adjust the font weight as needed
                      },
                    },
                  }}
                  // subHeader
                  // subHeaderComponent={subHeaderComponent}
                />
              </>
            )}
          </RB.Card.Body>
        </RB.Card>
        <RB.Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedLog([]);
          }}
        >
          <RB.Modal.Header closeButton>
            <RB.Modal.Title>
              Regularize Attendance(
              {moment(currentLogDate).format("DD-MM-YYYY")})
            </RB.Modal.Title>
          </RB.Modal.Header>
          <RB.Modal.Body>
            <div className="list-group">
              {selectedLog.map((log, index) => {
                const checkInTime = log.checkIn.date
                  ? moment(log.checkIn.date).format("hh:mm A")
                  : "Set Time";
                const checkOutTime =
                  log.checkOut && log.checkOut.date
                    ? moment(log.checkOut.date).format("hh:mm A")
                    : "Set Time";

                return (
                  <div
                    key={index}
                    className="list-group-item d-flex align-items-center"
                  >
                    <div className="d-flex align-items-center">
                      <SouthWest />
                      <DatePicker
                        selected={
                          log.checkIn.new_time
                            ? new Date(log.checkIn.new_time)
                            : log.checkIn.date
                            ? log.checkIn.date.endsWith("00:00:00")
                              ? null // Render null when date ends with 00:00:00
                              : new Date(log.checkIn.date)
                            : null
                        }
                        onChange={(date) =>
                          handleEditTime(index, "checkIn", date, log)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        dateFormat="hh:mm aa"
                        className="form-control"
                        placeholderText="Set Time"
                      />
                    </div>
                    <div className="mx-2">-</div>
                    <div className="d-flex align-items-center">
                      <NorthEast />
                      <DatePicker
                        selected={
                          log.checkOut && log.checkOut.new_time
                            ? new Date(log.checkOut.new_time)
                            : log.checkOut && log.checkOut.date
                            ? log.checkOut.date.endsWith("00:00:00")
                              ? null
                              : new Date(log.checkOut.date)
                            : null
                        }
                        onChange={(date) =>
                          handleEditTime(index, "checkOut", date, log)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        dateFormat="hh:mm aa"
                        className="form-control"
                        placeholderText="Set Time"
                      />
                    </div>
                    {index === selectedLog.length - 1 && (
                      <RB.Button
                        variant="outline-primary"
                        onClick={handleAddTime}
                        size="sm"
                        className="ms-2"
                      >
                        <Add />
                      </RB.Button>
                    )}
                  </div>
                );
              })}
            </div>
          </RB.Modal.Body>

          <RB.Modal.Footer>
            <RB.Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                setSelectedLog([]);
              }}
            >
              Close
            </RB.Button>
            <RB.Button variant="primary" onClick={handleSaveRegularize}>
              Save Changes
            </RB.Button>
          </RB.Modal.Footer>
        </RB.Modal>
      </div>
    </>
  );
}

export default List;
