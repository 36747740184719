import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import {
  Cancel,
  Delete,
  Done,
  MoreHoriz,
  PendingActions,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CloseCircle, Eye, More, TickCircle } from "iconsax-react";
import ReasonModal from "../ReasonModal";

function OtherTeamRegularize(props) {
  const [TeamList, setTeamList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [validated, setValidated] = useState(false);
  const [isReject, SetIsReject] = useState(false);
  const [id, setId] = useState("");
  const [resignid, setResignId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    reason: "",
  });
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const getStatusName = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Approved";
      case "2":
        return "Reject";

      default:
        return "";
    }
  };

  const columns = [
    {
      name: "Employee Id",
      selector: (row) => row.emp_code,
    },
    {
      name: "Employee Name",
      selector: (row) => row.employee_name,
    },
    {
      name: "Resign Date",
      selector: (row) => row.resign_date,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      cell: (row) => {
        const wordCount = row?.reason?.split(" ").length;
        console.log(wordCount);
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Reason", row.reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.reason}</span>
        );
      },
    },
    {
      name: "Action By",
      selector: (row) => row.auth_name,
    },
    {
      name: "Status",
      selector: (row) => getStatusName(row.resign_status),
    },
    {
      name: "Team Log",
      center: true,
      selector: (row) => (
        <>
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0 overflow-auto popover-scroll ">
                  <div className="list-group ">
                    {row.logData.length !== 0 ? (
                      <>
                        {row.logData.map((log, index) => (
                          <div className="list-group-item ">
                            {log.reporting_manager_name}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="list-group-item">...</div>
                      </>
                    )}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <RB.Button
              className="btn-circle"
              variant="outline-secondary"
              size="sm"
            >
              <PendingActions />
            </RB.Button>
          </RB.OverlayTrigger>
        </>
      ),
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          {row.resign_status === "0" ? (
            <>
              <RB.OverlayTrigger
                trigger="click"
                rootClose
                key="bottom"
                placement="bottom"
                overlay={
                  <RB.Popover id={`popover-positioned-bottom`}>
                    <RB.Popover.Body className="p-0">
                      <div className="list-group">
                        <Link
                          className="list-group-item list-group-item-action"
                          onClick={(e) => {
                            e.preventDefault();
                            handleAccept(row)();
                          }}
                        >
                          <TickCircle /> Accept
                        </Link>
                        <Link
                          className="list-group-item list-group-item-action"
                          onClick={(e) => {
                            e.preventDefault();
                            handleReject(row)();
                          }}
                        >
                          <CloseCircle /> Reject
                        </Link>
                      </div>
                    </RB.Popover.Body>
                  </RB.Popover>
                }
              >
                <Link className="btn btn-flat">
                  <More />
                </Link>
              </RB.OverlayTrigger>
            </>
          ) : (
            ""
          )}
        </>
      ),
      // omit: !hasStatusZero,
    },
  ];

  // const hasStatusZero = resignation?.result?.some(
  //   (resignation) => resignation.resign_status === "0"
  // );

  const handleAccept = useCallback((row) => async () => {
    const propdata = {
      mode: "add",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleReject = useCallback((row) => async () => {
    setId(row.id);
    setResignId(row.resign_id);
    handleShow();
  });
  useEffect(() => {
    getTeamLIst();
  }, [page, filterText]);

  const getTeamLIst = () => {
    utils
      .callAPI(
        "get",
        `/resignation/team-list?type=other&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setTeamList(res);
        } else {
          setTeamList([]);
        }
      })
      .catch((err) => {
        setTeamList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSbmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      SetIsReject(true);
      handleClose();
    }
  };

  useEffect(() => {
    if (isReject) {
      var bodyFormData = new FormData();
      // bodyFormData.append("id", id);
      bodyFormData.append("resign_id", id);
      bodyFormData.append("id", resignid);

      bodyFormData.append("last_working_date", "");
      bodyFormData.append("notice_period", "");
      bodyFormData.append("resign_accept_date", "");
      bodyFormData.append("resign_status", "2");
      bodyFormData.append("reject_reason", formData.reason);
      // bodyFormData.append("reject_reason", formData.rejectReason);

      utils
        .callAPI("post", `/resignation/approval`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setFormData({ reason: "" });
            handleClose();
            SetIsReject(false);
            setId("");
            setResignId();
            getTeamLIst();
          } else {
            SetIsReject(false);
          }
        })
        .catch((err) => {
          SetIsReject(false);
        });
    }
  }, [isReject]);

  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow ">
          {/* <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action">
            {utils.hasPermission(primaryMenuRes, "User", "user.create") && (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Add User
              </RB.Button>
            )}
          </div>
        </RB.Card.Header> */}
          <RB.Card.Body>
            <DataTable
              columns={columns}
              data={TeamList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={TeamList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              striped
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
            />
          </RB.Card.Body>
        </RB.Card>
      </div>
      <RB.Modal show={show} onHide={handleClose} backdrop="static">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body className="m-0">
          <RB.Form noValidate validated={validated} onSubmit={handleSbmit}>
            <RB.Row className="m-o">
              <RB.Col md={12}>
                <RB.FloatingLabel
                  label="Reason"
                  className="floating-label-fancy"
                >
                  <RB.Form.Control
                    as="textarea"
                    placeholder="Enter Reason"
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                    required
                  />
                </RB.FloatingLabel>
              </RB.Col>
            </RB.Row>
            <RB.Row className="justify-content-end mt-3">
              <RB.Col className="col-auto">
                <RB.Button
                  variant="secondary"
                  className="me-2"
                  onClick={handleClose}
                >
                  Close
                </RB.Button>
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Modal.Body>
        <RB.Modal.Footer className="pt-0"></RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default OtherTeamRegularize;
