import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Visibility } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import { Link } from "react-router-dom";
import { ArrowDown } from "iconsax-react";
import * as XLSX from "xlsx";

function ScanView(props) {
  const { rowData } = props;

  const [CouponHistoy, setCouponHistoy] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const [CouponList, setCouponList] = useState([]);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [empList, setEmpList] = useState([]);
  const getFormattedMonthYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    const currentDate = new Date();
    return getFormattedMonthYear(currentDate);
  });

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const primaryMenuRes = useSelector(
    (state) => state.primaryMenu.primaryMenu.permissions
  );

  const [active, setActive] = useState(false);
  const csvLink = createRef();

  const handleBackClick = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row, index) => (page - 1) * countPerPage + index + 1,
      sortable: false,
      maxWidth: "80px", // Adjust the maximum width as needed
      minWidth: "60px",
    },
    {
      name: "Coupon Name",
      selector: (row) => row.coupon_name,
    },
    {
      name: "Coupon Code",
      selector: (row) => row.coupon_code,
    },
    {
      name: "Total Amount",
      selector: (row) => row?.amount,
    },
    {
      name: "Date",
      selector: (row) => moment(row.date).format("DD-MM-yyyy"),
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <>
    //       {row?.coupon_count > 0 && (
    //         <RB.Button
    //           className="btn-circle"
    //           variant="outline-primary"
    //           size="sm"
    //           onClick={handleView(row)}
    //         >
    //           <Visibility />
    //         </RB.Button>
    //       )}
    //     </>
    //   ),
    // },
  ];

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  useEffect(() => {
    getCoupon();
    // getEmployeeList();
  }, [page, rowData?.id]);

  const getCoupon = () => {
    utils
      .callAPI(
        "post",
        `Coupon/viewScannedCoupon?client_id=${rowData?.id}&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setCouponList(res.coupon);
          setCouponHistoy(res);
          setFilterReset(false);
        } else {
          setCouponList([]);
          setCouponHistoy([]);
          setFilterReset(false);
        }
      })
      .catch((err) => {
        setCouponList([]);
        setCouponHistoy([]);
        setFilterReset(false);
      });
  };

  //   const getEmployeeList = () => {
  //     utils
  //       .callAPI("get", `/employee/list`)
  //       .then((res) => {
  //         if (typeof res !== "undefined") {
  //           setEmpList(res.result);
  //         } else {
  //           setEmpList([]);
  //         }
  //       })
  //       .catch((err) => {
  //         setEmpList([]);
  //       });
  //   };

  function handleDownload() {
    setCsvData([]);

    utils
      .callAPI(
        "post",
        `Coupon/viewScannedCoupon?client_id=${
          rowData?.id
        }&page=${0}&per_page=${0}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          const csvHeader = `Sr.No.,Date,Coupon Name,Coupon Code,Total Amount`;

          const csvRows = res?.coupon?.map((cop, index) => {
            return `${index + 1},${moment(cop.date).format('DD-MM-YYYY')},${cop.coupon_name},${
              cop.coupon_code
            },${parseFloat(cop.amount).toFixed(2)}`;
          });

          const csvContent = [csvHeader, ...csvRows].join("\n");
          // console.log(csvContent)
          // setCsvData(csvContent);
          // setActive(true);
          generateCSV(csvContent);

        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }
  function generateCSV(filteredData) {
    console.log(filteredData)
    const blob = new Blob([filteredData], { type: "text/csv;charset=utf-8;" });

    // Create a temporary download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Coupon_scan.csv";

    // Append the link to the body temporarily and trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
    // const ws = XLSX.utils.json_to_sheet(filteredData, {
    //   header: Object.keys(filteredData[0]),
    //   skipHeader: true,
    // });

    // const wb = XLSX.utils.book_new();

    // XLSX.utils.book_append_sheet(wb, ws, "Employees");

    // const csvOutput = XLSX.utils.sheet_to_csv(ws);

    // const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = "Coupon_scan.csv";

    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  //     return (
  //       <FilterComponent
  //         onFilter={(e) => setFilterText(e.target.value)}
  //         onClear={handleClear}
  //         filterText={filterText}
  //       />
  //     );
  //   }, [filterText, resetPaginationToggle]);

  //   const months = useMemo(() => {
  //     const options = [];
  //     const currentDate = new Date();
  //     for (let i = 0; i < 12; i++) {
  //       const date = new Date(currentDate);
  //       date.setMonth(date.getMonth() - i);
  //       const monthYear = getFormattedMonthYear(date);
  //       options.push(
  //         <option key={i} value={monthYear}>
  //           {monthYear}
  //         </option>
  //       );
  //     }
  //     return options;
  //   }, []);

  //   const handleFilter = () => {
  //     getCouponHistoy();
  //   };

  //   const handleReset = () => {
  //     const currentDate = new Date();
  //     setSelectedMonthYear(getFormattedMonthYear(currentDate));
  //     setSelectedEmployee("");
  //     setFilterReset(true);
  //   };

  //   useEffect(() => {
  //     if (filterReset) {
  //       getCouponHistoy();
  //     }
  //   }, [filterReset]);
  return (
    <div className="section-body">
      <RB.Card className="card">
        <RB.Card.Header className="border-0">
          <h4>{rowData ? rowData.name : "List"}</h4>
          <div className="card-header-action">
            <Link
              className="btn btn-underline"
              onClick={handleBackClick(rowData)}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          {CouponHistoy.coupon_count ? (
            <RB.Row>
              <div className="col-md-6">
                <RB.Card>
                  <RB.CardBody>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-md avatar-circle bg-light text-body-secondary">
                          <i className="material-icons-outlined">discount</i>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-muted mb-1">Coupon Count</h6>
                        <h2 className="text-dark  mb-0 ">
                          {" "}
                          {CouponHistoy.coupon_count}
                        </h2>
                      </div>
                    </div>
                  </RB.CardBody>
                </RB.Card>
              </div>

              <div className="col-md-6">
                <RB.Card>
                  <RB.CardBody>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-md avatar-circle bg-light text-body-secondary">
                          <i className="material-icons-outlined">
                            currency_rupee
                          </i>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-muted mb-1">Total Amount</h6>
                        <h2 className="text-dark  mb-0 ">
                          {CouponHistoy.total_amount}
                        </h2>
                      </div>
                    </div>
                  </RB.CardBody>
                </RB.Card>
              </div>

              <div className="col-md-6">
                <RB.Card>
                  <RB.CardBody>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-md avatar-circle bg-light text-body-secondary">
                          <i className="material-icons-outlined">
                            currency_rupee
                          </i>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-muted mb-1">Wallet Amount</h6>
                        <h2 className="text-dark  mb-0 ">
                          {" "}
                          {CouponHistoy.wallet_amount}
                        </h2>
                      </div>
                    </div>
                  </RB.CardBody>
                </RB.Card>
              </div>

              <div className="col-md-6">
                <RB.Card>
                  <RB.CardBody>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-md avatar-circle bg-light text-body-secondary">
                          <i className="material-icons-outlined">
                            account_balance_wallet
                          </i>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-muted mb-1">Withdrawal Amount</h6>
                        <h2 className="text-dark  mb-0 ">
                          {" "}
                          {CouponHistoy.withdraw_amount}
                        </h2>
                      </div>
                    </div>
                  </RB.CardBody>
                </RB.Card>
              </div>
            </RB.Row>
          ) : null}

<RB.Row className="justify-content-between">
<RB.Col></RB.Col>
            {/* <RB.Col md="auto">
              <div className="d-flex justify-content-start" text-center>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                >
                  <option>Select an employee...</option>
                  {empList?.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {`${employee.firstName} ${employee.lastName}`}
                    </option>
                  ))}
                </RB.Form.Select>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedMonthYear}
                  onChange={(e) => setSelectedMonthYear(e.target.value)}
                >
                  <option value="">Select a month-year...</option>
                  {months}
                </RB.Form.Select>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={handleFilter}
                >
                  Find
                </RB.Button>
                <RB.Button
                  className="ms-2"
                  variant="secondary"
                  onClick={handleReset}
                >
                  Reset
                </RB.Button>
              </div>
            </RB.Col> */}
            <RB.Col md="auto">
              {" "}
              <div className=" justify-content-end" text-center>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  <ArrowDown />
                </RB.Button>

                <CSVLink data={csvData} ref={csvLink} target="_blank" />
              </div>
            </RB.Col>
          </RB.Row>
          <DataTable
            columns={columns}
            data={CouponList}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={CouponHistoy.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default ScanView;
