import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "./axios";
import { useSelector } from "react-redux";
export const isAuthenticated = () => {
  let token = localStorage.getItem("access_token");
  if (!token || token === "") {
    return false;
  } else {
    return true;
  }
};
export const showMessage = (msg, type) => {
  if (type === "success") {
    toast.success(msg, {
      position: "top-right",
    });
  } else if (type === "error") {
    toast.error(msg, {
      position: "top-right",
    });
  } else {
    toast.info(msg, {
      position: "top-right",
    });
  }
};
export const callAPI = (method, endpoint, data) => {
  let errors;

  let tkn = localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : localStorage.getItem("artisan_token")
    : localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : localStorage.getItem("artisan_token");

  // axios.defaults.headers.common["Authorization"] = tkn ?  "Bearer " + tkn : ''

  if (tkn) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + tkn;
  }
  // axios.defaults.headers.common['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (method === "post") {
    return axios
      .post(endpoint, data)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          const errors = res.data.errors;

          if (Array.isArray(errors)) {
            errors.forEach((error) => {
              showMessage(error, "error");
            });
          }
          else if (typeof errors === "object") {
            Object.keys(errors).forEach((key) => {
              showMessage(`${errors[key]}`, "error");
            });
          }
          else if (typeof errors === "string") {
            showMessage(errors, "error");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          if (typeof errors === "object") {
            Object.keys(errors).map(function (key, index) {
              showMessage(`${errors[key]}`, "error");
            });
          } else if (typeof errors === "string") {
            showMessage(errors, "error");
          }
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = '/';
        }
      });
  }
  if (method === "get") {
    return axios
      .get(endpoint)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          if (res.data.hasOwnProperty("pagination")) {
            let res_data = {
              data: res.data.data,
              pagination: res.data.pagination,
            };
            return res_data;
          } else {
            return res.data.data;
          }
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
        //
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          if (Object.keys(err.response.data.data).length !== 0) {
            Object.keys(errors).map(function (key, index) {
              showMessage(`${errors[key]}`, "error");
            });
          }
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          //window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = "/";
        }
      });
  }
  if (method === "delete") {
    return axios
      .delete(endpoint, data)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = '/';
        }
      });
  }
};

export const hasPermission = (primaryMenuRes, module, permission) => {
  const userPermissions = primaryMenuRes?.find(
    (item) => item.module === module
  )?.permissions;
  return userPermissions?.includes(permission) ?? false;
};
