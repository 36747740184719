import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";

function Notification() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [notificationList, setNotificationList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  useEffect(() => {
    getNotification(currentPage);
  }, [currentPage]);

  const getNotification = (page) => {
    utils
      .callAPI(
        "get",
        `/Notification/list?page=${page}&per_page=${itemsPerPage}`
      )
      .then((res) => {
        if (res && res.result) {
          setNotificationList(res.result);
          setCurrentPage(res.current_page);
          setTotalPages(res.pages);
          setTotalItems(res.total);
          setItemsPerPage(res.limit);
        } else {
          setNotificationList([]);
          setTotalPages(0);
        }
      })
      .catch((err) => {
        setNotificationList([]);
        setTotalPages(0);
        console.error("Error fetching notifications:", err);
      });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <RB.Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => paginate(i)}
        >
          {i}
        </RB.Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const handleNotification = useCallback(
    (notification) => (event) => {
      event.preventDefault();
      console.log(notification);

      if (notification.is_read === "0") {
        const bodyFormData = new FormData();
        bodyFormData.append("id", notification.id);
        utils
          .callAPI("post", `/Notification/set-read`, bodyFormData)
          .then(() => {
            getNotification(currentPage);
            redirect(notification);
          })
          .catch((err) => {
            console.error("Failed to mark notification as read:", err);
          });
      } else {
        redirect(notification);
      }
    },
    [navigate, getNotification, currentPage]
  );

  const redirect = (notification) => {
    const basePath = notification.extra.split("/").slice(0, -1).join("/");
    const isHRorAdmin = ["HR Manager", "Super Admin"].includes(
      userProfile?.user_role[0]?.roleName
    );

    switch (notification.type) {
      case "leave_request":
        if (isHRorAdmin && basePath.includes("team")) {
          navigate("/leave/leaves");
        } else {
          navigate(`${basePath}`);
        }
        break;
      case "resignation":
        if (isHRorAdmin && basePath.includes("team")) {
          navigate("/resignation/team-resignation-requests");
        } else {
          navigate(`${basePath}`);
        }
        break;
      case "new_employee":
        // navigate(`/employees/new-employee-list`);
        break;
      case "expense":
        if (
          userProfile?.user_role[0]?.roleName == "HR Manager" &&
          basePath.includes("team")
        ) {
          navigate("/expenses/all-expense");
        } else {
          navigate(`${basePath}`);
        }
        break;
      case "travel_request":
        navigate(`${basePath}`);
        break;
      case "regularization":
        navigate(`${basePath}`);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Notifications</h1>
          </div>
          <div className="section-body">
            <RB.Card className="card shadow ">
              <RB.Card.Body>
                <div className="dropdown-list-icons list-group">
                  {notificationList.map((notification) => (
                    <Link
                      key={notification.id}
                      className={`list-group-item mt-1 ${
                        notification.is_read == "0" ? "bg-light" : ""
                      }`}
                      onClick={handleNotification(notification)}
                    >
                      <div className="dropdown-item-desc">
                        <b>{notification.title}</b>
                        <div className="mb-1">{/* <b>Mahendra Ram</b> */}</div>
                        {notification.notification_text}
                        <div className="time text-body text-sm">
                          {moment(notification.createdAt).format(
                            "YYYY-MM-DD hh:mm"
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                {/* {notificationList.length > 10 && ( */}
                <RB.Pagination className="mt-4 justify-content-center">
                  <RB.Pagination.First
                    onClick={() => paginate(1)}
                    disabled={currentPage === 1}
                  />
                  <RB.Pagination.Prev
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {renderPageNumbers()}
                  <RB.Pagination.Next
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                  <RB.Pagination.Last
                    onClick={() => paginate(totalPages)}
                    disabled={currentPage === totalPages}
                  />
                </RB.Pagination>
                {/* )} */}
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Notification;
