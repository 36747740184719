import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Edit2, Trash } from "iconsax-react";

function Department() {
  const [departmentName, setDepartmentName] = useState("");
  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState();
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const statusList = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
  ];

  const columns = [
    {
      name: "Department Name",
      selector: (row) => row.department_name,
    },
    {
      name: "Status",
      selector: (row) => (row.isActive == "1" ? "Active" : "Inactive"),
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Department",
            "department.edit"
          ) && (
            <RB.Button className="btn-flat" onClick={handleEdit(row)}>
              <Edit2 />
            </RB.Button>
          )}{" "}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "Department",
            "department.delete"
          ) && (
            <RB.Button className="btn-flat ms-2" onClick={showDelete(row)}>
              <Trash />
            </RB.Button>
          )}
        </>
      ),
      omit:
        !utils.hasPermission(primaryMenuRes, "Department", "department.edit") &&
        !utils.hasPermission(primaryMenuRes, "Department", "department.delete"),
    },
  ];

  useEffect(() => {
    getDepartmentList();
  }, [page]);

  const getDepartmentList = () => {
    utils
      .callAPI("get", `/department/list?page=${page}&per_page=${countPerPage}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setData(res);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setData([]);
      });
  };

  const handleEdit = useCallback((row) => async () => {
    setEditId(row.id);
    setDepartmentName(row.department_name);
    setStatus(row.isActive);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/department/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getDepartmentList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const handleDepartmentNameChange = (event) => {
    setDepartmentName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  const handleClear = () => {
    setDepartmentName("");
    setEditId("");
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }

      bodyFormData.append("department_name", departmentName);
      bodyFormData.append("isActive", status);

      utils
        .callAPI("post", `/department/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setDepartmentName("");
            setStatus("");
            getDepartmentList();
          }
        })
        .catch((err) => {});
    }
  }, [isSubmit]);

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Department</h1>
          </div>
          <div className="section-body">
            <RB.Card className="">
              <RB.Card.Body className="p-4">
                <RB.Row>
                  {utils.hasPermission(
                    primaryMenuRes,
                    "Department",
                    "department.create"
                  ) && (
                    <RB.Col md={4}>
                      <RB.Card className="shadow-1">
                        <RB.Card.Body>
                          <RB.Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                          >
                            <div className="mb-3">
                              <RB.FloatingLabel
                                controlId="departmentName"
                                label="Department Name"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Control
                                  type="text"
                                  placeholder="Enter Department Name"
                                  value={departmentName}
                                  onChange={handleDepartmentNameChange}
                                  maxLength={32}
                                  required
                                />
                                <RB.Form.Control.Feedback type="invalid">
                                  Please enter the department name.
                                </RB.Form.Control.Feedback>
                              </RB.FloatingLabel>
                            </div>

                            <div className="mb-3">
                              <RB.FloatingLabel
                                controlId="status"
                                label="Status"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Control
                                  as="select"
                                  value={status}
                                  onChange={handleStatusChange}
                                  required
                                >
                                  {statusList.map((status) => (
                                    <option key={status.id} value={status.id}>
                                      {status.name}
                                    </option>
                                  ))}
                                </RB.Form.Control>
                                <RB.Form.Control.Feedback type="invalid">
                                  Please select a status.
                                </RB.Form.Control.Feedback>
                              </RB.FloatingLabel>
                            </div>
                            <div className="d-flex align-items-center gx-3">
                              <div>
                                <RB.Button type="submit" variant="primary">
                                  {editId ? "Update" : "Add"}
                                </RB.Button>
                              </div>
                              <div>
                                <Link
                                  className="text-underline ms-3"
                                  //	type="reset"
                                  //variant="primary"
                                  onClick={handleClear}
                                >
                                  {"Clear"}
                                </Link>
                              </div>
                            </div>
                          </RB.Form>
                        </RB.Card.Body>
                      </RB.Card>
                    </RB.Col>
                  )}

                  {utils.hasPermission(
                    primaryMenuRes,
                    "Department",
                    "department.view"
                  ) && (
                    <RB.Col
                      md={
                        utils.hasPermission(
                          primaryMenuRes,
                          "Department",
                          "department.create"
                        )
                          ? 8
                          : 12
                      }
                    >
                      <RB.Card className="shadow-1">
                        <RB.Card.Body>
                          <DataTable
                            columns={columns}
                            data={data.result}
                            highlightOnHover
                            responsive
                            pagination
                            striped
                            paginationServer
                            paginationPerPage={countPerPage}
                            paginationTotalRows={data.total}
                            paginationComponentOptions={{
                              noRowsPerPage: true,
                            }}
                            onChangePage={(page) => setPage(page)}
                            persistTableHead
                            customStyles={{
                              head: {
                                style: {
                                  fontSize: "0.875rem", // Adjust the font size as needed
                                  fontWeight: "bold", // Adjust the font weight as needed
                                },
                              },
                            }}
                          />
                        </RB.Card.Body>
                      </RB.Card>
                    </RB.Col>
                  )}
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Department;
