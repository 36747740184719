import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { Edit2, Trash } from "iconsax-react";
import { Link } from "react-router-dom";

function LeaveType() {
  const [leaveData, setLeaveData] = useState({
    leaveName: "",
    shortName: "",
    leave_deduct: "",
    isActive: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [editId, setEditId] = useState();
  const [leavetypeData, setLeavetypeData] = useState([]);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const statusList = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
  ];

  const columns = [
    {
      name: "Leave Name",
      selector: (row) => row.leave_name,
    },
    {
      name: (
        <div>
          Short Name <br />
        </div>
      ),
      selector: (row) => row.short_name,
    },
    {
      name: (
        <div>
          Leave Deduct <br />
        </div>
      ),
      selector: (row) => (row.leave_deduct == "0" ? "No" : "Yes"),
    },
    {
      name: (
        <div>
          Status <br />
        </div>
      ),
      selector: (row) => (row.isActive === "1" ? "Active" : "Inactive"),
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      center: true,
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Leave Type",
            "leave_type.edit"
          ) && (
            <RB.Button onClick={handleEdit(row)} className="btn-flat">
              <Edit2 />
            </RB.Button>
          )}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "Leave Type",
            "leave_type.delete"
          ) && (
            <RB.Button className="btn-flat ms-1" onClick={showDelete(row)}>
              <Trash />
            </RB.Button>
          )}
        </>
      ),
      omit:
        !utils.hasPermission(primaryMenuRes, "Leave Type", "leave_type.edit") &&
        !utils.hasPermission(primaryMenuRes, "Leave Type", "leave_type.delete"),
    },
  ];
  useEffect(() => {
    getLeaveTypeList();
  }, [page]);

  const getLeaveTypeList = () => {
    utils
      .callAPI(
        "get",
        `/leave/leave-type/list?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          console.log(res);
          setLeavetypeData(res);
        } else {
          setLeavetypeData({});
        }
      })
      .catch((err) => {
        setLeavetypeData({});
      });
  };

  const handleEdit = useCallback(
    (row) => () => {
      setEditId(row.id);
      setLeaveData({
        ...leaveData,
        leaveName: row.leave_name,
        shortName: row.short_name,
        leave_deduct: row.leave_deduct,
        isActive: row.isActive,
      });
    },
    []
  );

  const showDelete = useCallback(
    (row) => () => {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          utils
            .callAPI("get", `/leave/leave-type/delete?id=${row.id}`, "")
            .then((res) => {
              if (typeof res !== "undefined") {
                getLeaveTypeList();
              }
            })
            .catch((err) => {});
        }
      });
    },
    []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({ ...leaveData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }

      bodyFormData.append("leave_name", leaveData.leaveName);
      bodyFormData.append("short_name", leaveData.shortName);
      bodyFormData.append("leave_deduct", leaveData.leave_deduct);
      bodyFormData.append("isActive", leaveData.isActive);

      utils
        .callAPI("post", `/leave/leave-type/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setLeaveData({
              leaveName: "",
              shortName: "",
              leave_deduct: "",
              isActive: "",
            });
            getLeaveTypeList();
          }
        })
        .catch((err) => {});
    }
  }, [isSubmit]);

  const handleClear = () => {
    setLeaveData({
      leaveName: "",
      shortName: "",
      leave_deduct: "",
      isActive: "",
    });
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Leave Type</h1>
          </div>
          <div className="section-body">
            <RB.Card className="card shadow">
              <RB.Card.Body className="p-4">
                <RB.Row>
                  {" "}
                  {utils.hasPermission(
                    primaryMenuRes,
                    "Leave Type",
                    "leave_type.create"
                  ) && (
                    <RB.Col md={4} className="mb-3 mb-md-0">
                      <RB.Card className="h-100 shadow-1">
                        <RB.Card.Body>
                          <RB.Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                          >
                            <div className="mb-3">
                              <RB.FloatingLabel
                                controlId="leaveName"
                                label="Leave Name"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Control
                                  type="text"
                                  placeholder="Enter Leave Name"
                                  name="leaveName"
                                  value={leaveData.leaveName}
                                  onChange={handleChange}
                                  required
                                />
                                {/* <RB.Form.Control.Feedback type="invalid">
                        Please enter a leave name.
                      </RB.Form.Control.Feedback> */}
                              </RB.FloatingLabel>
                            </div>

                            <div className="mb-3">
                              <RB.FloatingLabel
                                controlId="shortName"
                                label="Short Name"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Control
                                  type="text"
                                  placeholder="Enter Short Name"
                                  name="shortName"
                                  value={leaveData.shortName}
                                  onChange={handleChange}
                                  required
                                />
                                {/* <RB.Form.Control.Feedback type="invalid">
                        Please enter a short name.
                      </RB.Form.Control.Feedback> */}
                              </RB.FloatingLabel>
                            </div>
                            <div className="mb-3">
                              <RB.FloatingLabel
                                label="Leave Deduct"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Control
                                  as="select"
                                  name="leave_deduct"
                                  value={leaveData.leave_deduct}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select an option...</option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </RB.Form.Control>
                              </RB.FloatingLabel>
                            </div>
                            <div className="mb-3">
                              <RB.FloatingLabel
                                controlId="status"
                                label="Status"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Select
                                  as="select"
                                  name="isActive"
                                  value={leaveData.isActive}
                                  onChange={handleChange}
                                  required
                                >
                                  {statusList.map((status) => (
                                    <option key={status.id} value={status.id}>
                                      {status.name}
                                    </option>
                                  ))}
                                </RB.Form.Select>
                                {/* <RB.Form.Control.Feedback type="invalid">
                        Please select a status.
                      </RB.Form.Control.Feedback> */}
                              </RB.FloatingLabel>
                            </div>
                            <div className="d-flex align-items-center gx-3">
                              <RB.Button type="submit" variant="primary">
                                Submit
                              </RB.Button>
                              <div>
                                <Link
                                  className="text-underline ms-3"
                                  //	type="reset"
                                  //variant="primary"
                                  onClick={handleClear}
                                >
                                  {"Clear"}
                                </Link>
                              </div>
                            </div>
                          </RB.Form>
                        </RB.Card.Body>
                      </RB.Card>
                    </RB.Col>
                  )}
                  {utils.hasPermission(
                    primaryMenuRes,
                    "Leave Type",
                    "leave_type.view"
                  ) && (
                    <RB.Col
                      md={
                        utils.hasPermission(
                          primaryMenuRes,
                          "Leave Type",
                          "leave_type.create"
                        )
                          ? 8
                          : 12
                      }
                    >
                      <RB.Card className="h-100 shadow-1">
                        <RB.Card.Body>
                          <DataTable
                            columns={columns}
                            data={leavetypeData}
                            highlightOnHover
                            responsive
                            striped
                            pagination
                            paginationServer
                            paginationTotalRows={leavetypeData.total}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                              noRowsPerPage: true,
                            }}
                            onChangePage={(page) => setPage(page)}
                            persistTableHead
                            customStyles={{
                              head: {
                                style: {
                                  fontSize: "0.875rem", // Adjust the font size as needed
                                  fontWeight: "bold", // Adjust the font weight as needed
                                },
                              },
                              // cell: {
                              //   style: {
                              //     whiteSpace: "normal",
                              //     minWidth: "150px", // Allow text wrapping
                              //   },
                              // },
                            }}
                            //   subHeader
                            // subHeaderComponent={subHeaderComponent}
                          />
                        </RB.Card.Body>
                      </RB.Card>
                    </RB.Col>
                  )}
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default LeaveType;
