import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import * as RB from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import "./holiday.css";
import { Edit, Edit2, Trash } from "iconsax-react";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

const localizer = momentLocalizer(moment);

function View(props) {
  const [holidayList, setHolidayList] = useState([]);
  const [allHolidayList, setAllHolidayList] = useState([]);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [holidayGroups, setHolidayGroups] = useState([]);
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const [holiday, setholiday] = useState(
    userProfile?.holiday_group ? userProfile?.holiday_group : ""
  );
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const userRole = userProfile?.user_role[0]?.roleName;

  useEffect(() => {
    if (userRole === "HR Manager" || userRole === "Super Admin") {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  }, [userRole]);

  const handleAddClick = () => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  useEffect(() => {
    getHolidays();
  }, [holiday, userProfile?.holiday_group]);

  const holidayId = utils.hasPermission(
    primaryMenuRes,
    "Holiday",
    "holiday.holidayGroup"
  )
    ? holiday
    : userProfile?.holiday_group;

  const getHolidays = () => {
    utils
      .callAPI("get", `/leave/holiday/list?group_id=${holidayId}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setAllHolidayList(res.result);
          const convertedEvents = res.result.map((event, index) => ({
            id: event.id,
            title: event.holiday_name,
            start: new Date(event.start_date),
            end: new Date(event.end_date),
            color: index % 3 === 0 ? "green" : index % 3 === 1 ? "blue" : "red",
            buttonsRendered: false,
          }));
          setHolidayList(convertedEvents);
        } else {
          setHolidayList([]);
        }
      })
      .catch((err) => {
        setHolidayList([]);
      });
  };

  useEffect(() => {
    utils
      .callAPI("get", "/leave/holiday/group-list")
      .then((res) => {
        if (res && res.result && res.result.length > 0) {
          setHolidayGroups(res.result);
        }
      })
      .catch((err) => {
        // console.error("Error fetching holiday groups:", err);
      });
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.color;
    const style = {
      backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "none",
      display: "block",
    };
    return {
      style: style,
    };
  };

  //   const customDayNamesStyle = `
  //     .rbc-row .rbc-header {
  //       background-color: #9675CE;
  //       color: white;
  //     }
  //   `;

  const renderHolidayGroupOptions = () => {
    return holidayGroups.map((group) => (
      <option
        key={group.id}
        value={group.id}
        selected={userProfile?.holiday_group ? userProfile?.holiday_group : ""}
      >
        {group.group_name}
      </option>
    ));
  };

  const handleEditClick = (event) => {
    const holidayEvent = allHolidayList.find((item) => item.id === event.id);
    console.log(event.id);
    const propdata = {
      mode: "edit",
      rowData: holidayEvent,
    };
    props.onChange(propdata);
  };

  const handleDeleteClick = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/leave/holiday/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getHolidays();
            }
          })
          .catch((err) => {});
      }
    });
  };
  const renderEvent = ({ event }) => {
    const today = new Date();
    const eventEndDate = new Date(event.end);
    const eventStartDate = new Date(event.start);
    const isEndDate = eventEndDate.toDateString() === event.end.toDateString();
    const showButtons = eventEndDate >= today;
    return (
      <div>
        {isButtonVisible ? (
          <>
            <span>{event.title}</span>
            {isEndDate && showButtons && (
              <div className="event-buttons">
                {utils.hasPermission(
                  primaryMenuRes,
                  "Holiday",
                  "holiday.delete"
                ) && (
                  <button
                    className="btn btn-flat btn-secondary"
                    onClick={() => handleDeleteClick(event)}
                  >
                    <Trash />
                  </button>
                )}
                {utils.hasPermission(
                  primaryMenuRes,
                  "Holiday",
                  "holiday.edit"
                ) && (
                  <button
                    className="btn btn-flat btn-secondary"
                    onClick={() => handleEditClick(event)}
                  >
                    <Edit2 />
                  </button>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <span>{event.title}</span>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="section-body">
      {/* <style>{customDayNamesStyle}</style> */}
      <RB.Card className="">
        <RB.Card.Header>
          <h4>View Holidays</h4>
          {utils.hasPermission(
            primaryMenuRes,
            "Holiday",
            "holiday.holidayGroup"
          ) && (
            <div>
              <RB.Form.Select
                name="holidayGroup"
                value={holiday}
                onChange={(e) => setholiday(e.target.value)}
                required
              >
                <option value="">Select Holiday Group...</option>
                {renderHolidayGroupOptions()}
                <option value="Other">Other</option>
              </RB.Form.Select>
            </div>
          )}
          <div className="card-header-action mt-0 ms-0 ms-md-auto">
            {utils.hasPermission(
              primaryMenuRes,
              "Holiday",
              "holiday.create"
            ) && (
              <RB.Button
                className="btn btn-primary me-md-2"
                onClick={handleAddClick}
              >
                Add Holiday
              </RB.Button>
            )}
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(primaryMenuRes, "Holiday", "holiday.view") && (
            <div className="custom-calendar">
              <Calendar
                localizer={localizer}
                events={holidayList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "500px" }}
                views={["month", "week", "day"]}
                //   defaultView="month"
                //   eventPropGetter={eventStyleGetter}
                // onSelectEvent={(event) => console.log(event)}
                components={{
                  event: renderEvent,
                }}
              />
            </div>
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default View;
